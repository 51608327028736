import EmojiPicker from "@emoji-mart/react";
// import emojiData from "@emoji-mart/data";
import emojiData from "@emoji-mart/data/sets/14/twitter.json";

import { Dialog, Switch, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import Select from "./Select";
import {
  RoutineItemType,
  RoutineType,
  TargetAmountType,
  TargetType,
  UnitType,
} from "./types";
import { classNames, getEmojiUrl, TYPE_EXAMPLES, UNITS } from "./utils";
import Button from "./Button";

const RoutineItemForm = ({
  item,
  setItem,
  type,
}: //   close,
{
  item: RoutineItemType;
  setItem: (
    i: RoutineItemType | ((o: RoutineItemType) => RoutineItemType)
  ) => void;
  type: RoutineType;
  //   close: () => void;
}) => {
  type AmountType = "value" | "previous" | "average";
  const [openEmoji, setOpenEmoji] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [neutralOn, setNeutralOn] = useState(false);
  // const [targetAmountType, setTargetAmountType] = useState<AmountType>("value");
  const [neutralTargetAmountType, setNeutralTargetAmountType] =
    useState<AmountType>("value");

  const TARGET_TYPES: { [key in TargetType]: string } = {
    log: "No Target",
    gte: "At Least",
    gt: "More Than",
    lte: "At Most",
    lt: "Less Than",
  };
  const getTargetDescription = (
    targetAmount: TargetAmountType | undefined,
    amountType: "value" | "previous" | "average",
    aim = "positive"
  ) => {
    if (!item.targetType || item.targetType === "log")
      return aim === "positive" ? (
        <span>
          There is no target. The value will be logged but there will be no
          score.
        </span>
      ) : (
        ""
      );

    // if (amountType === "value" && targetAmount === undefined) return "";
    return (
      <span>
        All values{" "}
        {
          { gte: "of at least", gt: "over", lte: "of at most", lt: "under" }[
            item.targetType
          ]
        }{" "}
        <span className="font-bold">
          {amountType === "value"
            ? targetAmount || 0
            : {
                previous: "the previous entry",
                average: "the average of previous entries",
              }[amountType]}
        </span>{" "}
        are scored as{" "}
        <span
          className={
            aim === "positive"
              ? "text-green-600 dark:text-green-400"
              : "text-amber-600 dark:text-amber-400"
          }
        >
          {aim}
        </span>
        .
      </span>
    );
  };
  const AddNumber = () => (
    <div className="flex flex-col space-y-6">
      <div>
        <div className="block text-sm font-medium leading-6 text-green-500">
          Positive Target
        </div>
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 w-full sm:space-x-2">
          <div className="w-full sm:w-1/3">
            <Select
              value={item.targetType || "log"}
              onChange={(targetType) =>
                setItem((i) => ({ ...i, targetType: targetType as TargetType }))
              }
              options={(Object.keys(TARGET_TYPES) as TargetType[]).map(
                (id) => ({ id, title: TARGET_TYPES[id] })
              )}
              // className="w-20"
            />
          </div>
          {item.targetType && item.targetType !== "log" && (
            <div className="w-full sm:w-1/3">
              <Select
                value={
                  (typeof item.targetAmount === "number" ||
                  item.targetAmount === undefined
                    ? "value"
                    : item.targetAmount) as TargetAmountType
                }
                onChange={(t) => {
                  // setTargetAmountType(t as AmountType);
                  setItem((i) => ({
                    ...i,
                    targetAmount: (t === "value" ? 0 : t) as TargetAmountType,
                  }));
                }}
                options={["value", "previous", "average"].map((id) => ({
                  id,
                  title: id,
                }))}
              />
            </div>
          )}
          {item.targetType &&
            item.targetType !== "log" &&
            (!item.targetAmount || typeof item.targetAmount === "number") && (
              <input
                type="number"
                className="w-full sm:w-1/3 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                // placeholder={`e.g. ${selectedType.example}`}
                placeholder="0"
                // value={item.targetAmount}
                defaultValue={item.targetAmount}
                onBlur={(e) =>
                  setItem((i) => ({
                    ...i,
                    targetAmount: parseFloat(e.target.value),
                  }))
                }
              />
            )}
        </div>
        <div className="italic text-xs">
          {getTargetDescription(
            item.targetAmount,
            !item.targetAmount || typeof item.targetAmount === "number"
              ? "value"
              : item.targetAmount
          )}
        </div>
      </div>
      <div className={(item.targetType || "log") === "log" ? "opacity-30" : ""}>
        <div className="block text-sm font-medium leading-6 text-amber-500">
          Neutral Target
        </div>
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 w-full sm:space-x-2">
          <div className="w-full sm:w-1/3">
            <Select
              onChange={(id) => setNeutralOn(Boolean(id))}
              value={neutralOn ? 1 : 0}
              options={[
                { id: 0, title: "None" },
                { id: 1, title: TARGET_TYPES[item.targetType || "log"] },
              ]}
              disabled={(item.targetType || "log") === "log"}
            />
          </div>
          {item.targetType && item.targetType !== "log" && neutralOn && (
            <div className="w-full sm:w-1/3">
              <Select
                value={neutralTargetAmountType}
                onChange={(t) => setNeutralTargetAmountType(t as AmountType)}
                options={["value", "previous", "average"].map((id) => ({
                  id,
                  title: id,
                }))}
              />
            </div>
          )}
          {item.targetType &&
            item.targetType !== "log" &&
            neutralOn &&
            (!neutralTargetAmountType ||
              neutralTargetAmountType === "value") && (
              <input
                type="number"
                className="w-full sm:w-1/3 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
                // placeholder={`e.g. ${selectedType.example}`}
                placeholder="0"
                defaultValue={item.targetAmountNeutral}
                onBlur={(e) =>
                  setItem((i) => ({
                    ...i,
                    targetAmountNeutral: parseFloat(e.target.value),
                  }))
                }
              />
            )}
        </div>
        <div className="italic text-xs">
          {neutralOn &&
            getTargetDescription(
              item.targetAmountNeutral,
              neutralTargetAmountType,
              "neutral"
            )}
        </div>
      </div>
      {advanced && (
        <div>
          <div className="block text-sm font-medium leading-6">Unit</div>
          <div className="flex">
            <Select
              value={item.unit || "count"}
              onChange={(unit) =>
                setItem((i) => ({ ...i, unit: unit as UnitType }))
              }
              options={UNITS.map((id) => ({ id, title: id }))}
            />
          </div>
        </div>
      )}
      {advanced && (
        <Switch.Group as="div" className="flex items-center justify-between">
          <span className="flex flex-grow flex-col">
            <Switch.Label
              as="span"
              className="text-sm font-medium leading-6"
              passive
            >
              Thousands?
            </Switch.Label>
            <Switch.Description
              as="span"
              className="text-xs text-gray-500/90 dark:text-gray-300/80"
            >
              Should the value be counted in thousands? For instance, if
              entering your daily steps, you may wish to just round to the
              nearest thousand and enter "10" instead of "10000".
            </Switch.Description>
          </span>
          <Switch
            checked={item.thousands}
            onChange={(thousands) => setItem((i) => ({ ...i, thousands }))}
            className={classNames(
              item.thousands ? "bg-teal-600" : "bg-gray-200",
              "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                item.thousands ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
        </Switch.Group>
      )}
    </div>
  );

  return (
    <div className="mt-2 flex flex-col space-y-6">
      <div>
        <label className="block text-sm font-medium leading-6">Title</label>
        <div className="mt-2">
          <input
            type="text"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
            placeholder={`e.g. ${TYPE_EXAMPLES[type]}`}
            value={item.name}
            onChange={(e) =>
              setItem((i) => ({
                ...i,
                name: e.target.value,
              }))
            }
          />
        </div>
      </div>

      <div className="flex flex-row items-center">
        <img
          src={getEmojiUrl(item.emoji)}
          alt={item.name}
          className={classNames("w-6 h-6 aspect-square cursor-pointer")}
          onClick={() => setOpenEmoji(true)}
        />
        {/* <button
          onClick={() => setOpenEmoji(true)}
          className="ml-2 border rounded-md text-sm px-2 py-1"
        >
          Change Emoji
        </button> */}
        <Button
          onClick={() => setOpenEmoji(true)}
          label="Change Emoji"
          size="sm"
          variant="outline"
          className="ml-2"
        />
      </div>

      {type === "number" && <AddNumber />}

      {!advanced && (
        <div>
          <Button
            label="Show Advanced Settings"
            variant="outline"
            onClick={() => setAdvanced(true)}
            size="sm"
          />
        </div>
      )}
      {advanced && (
        <>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6"
            >
              Importance ({item.importance}/10)
            </label>
            <div className="mt-2">
              <input
                type="range"
                min={0}
                max={10}
                value={item.importance}
                // value={value !== undefined ? value : 5}
                className={classNames(
                  "w-full range appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-thumb]:bg-gray-300 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-5  [&::-webkit-slider-thumb]:h-5 [&::-webkit-slider-thumb]:rounded-full focus:outline-none",

                  "[&::-webkit-slider-runnable-track]:bg-teal-600"
                )}
                step={1}
                onChange={(e) =>
                  setItem((i) => ({
                    ...i,
                    importance: Math.min(
                      Math.max(0, parseInt(e.target.value)),
                      10
                    ),
                  }))
                }
              />
            </div>
            <div className="italicx text-xs text-gray-500/90 dark:text-gray-300/80 mt-1">
              This will be used for calculating your daily score. If you set the
              importance to zero, it won't affect your score no matter what.
            </div>
          </div>
          {["rating", "task"].includes(item.type) && (
            <Switch.Group
              as="div"
              className="flex items-center justify-between"
            >
              <span className="flex flex-grow flex-col">
                <Switch.Label
                  as="span"
                  className="text-sm font-medium leading-6"
                  passive
                >
                  Invert Score
                </Switch.Label>
                <Switch.Description
                  as="span"
                  className="text-xs text-gray-500/90 dark:text-gray-300/80"
                >
                  {item.type === "rating" && (
                    <>
                      Inverting will mean that 0/10 is the most positive and
                      10/10 is negative. Useful if you're measuring something
                      like stress.
                    </>
                  )}
                  {item.type === "task" && (
                    <>
                      Inverting will mean that a tick is negative and a cross is
                      positive for your score. For instance if you're logging
                      alcohol or sugar.
                    </>
                  )}
                </Switch.Description>
              </span>
              <Switch
                checked={item.invertScore}
                onChange={(invertScore) =>
                  setItem((i) => ({ ...i, invertScore }))
                }
                className={classNames(
                  item.invertScore ? "bg-teal-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    item.invertScore ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          )}
          <Switch.Group as="div" className="flex items-center justify-between">
            <span className="flex flex-grow flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium leading-6"
                passive
              >
                Ignore Blanks
              </Switch.Label>
              <Switch.Description
                as="span"
                className="text-xs text-gray-500/90 dark:text-gray-300/80"
              >
                When enabled, this item will only be included in your score
                calculation when it has a value.
              </Switch.Description>
            </span>
            <Switch
              checked={item.excludeEmpty}
              onChange={(excludeEmpty) =>
                setItem((i) => ({ ...i, excludeEmpty }))
              }
              className={classNames(
                item.excludeEmpty ? "bg-teal-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  item.excludeEmpty ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          </Switch.Group>
        </>
      )}
      {/* {selectedType.id === "task" && <AddTask />}
    {selectedType.id === "thumb" && <AddThumb />}
    {selectedType.id === "rating" && <AddRating />} */}

      <Transition.Root show={openEmoji} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => setOpenEmoji(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hiddenx rounded-lg bg-white dark:bg-gray-800 dark:text-white text-left shadow-xl transition-all ">
                  <EmojiPicker
                    data={emojiData}
                    onEmojiSelect={(e: any) => {
                      console.log("emoji", e, emojiData);
                      setItem((i) => ({
                        ...i,
                        emoji:
                          (e.unified as string).split("-").length === 2
                            ? e.unified.replace(/-fe0f$/, "")
                            : e.unified,
                      }));
                      setOpenEmoji(false);
                    }}
                    // className="z-50"
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default RoutineItemForm;
