import React from "react";
const PrivacyPolicy = () => {
  return (
    <div className="max-w-lg p-4 mx-auto min-h-[calc(100%_-_100px)] flex flex-row justify-center items-center">
      <div className="flex flex-col space-y-2">
        <h2 className="text-xl font-semibold">Privacy Policy</h2>
        <p>
          Your data is never shared with any third-party companies and your
          email address isn't used for any marketing.
        </p>
        <h2 className="text-xl font-semibold pt-6">Data Deletion</h2>
        <p>
          Please contact <a href="mail:info@mento.co.uk">info@mento.co.uk</a> to
          request deletion of your data.
        </p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
