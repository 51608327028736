import { UnitType } from "./types";

// export const goalTypes: GoalType[] = [
//   {
//     id: "weight",
//     units: ["kg", "lbs"],
//     defaultEmoji: "⚖️",
//   },
//   {
//     id: "distance",
//     units: ["km", "miles"],
//     defaultEmoji: "🏃",
//   },
//   {
//     id: "checkbox",
//     units: [],
//     defaultEmoji: "✅",
//     defaultTarget: (checked: boolean) => checked,
//   },
//   {
//     id: "time",
//     units: ["seconds", "minutes", "hours"],
//     defaultEmoji: "⏱️",
//   },
//   {
//     id: "energy",
//     units: ["calories"],
//     defaultEmoji: "🍴",
//   },
//   {
//     id: "count",
//     units: ["ones", "thousands"],
//     defaultEmoji: "🔢",
//   },
// ];
export const TWEMOJI_BASE =
  "https://cdn.jsdelivr.net/gh/twitter/twemoji@14.0.2/assets/";

const baseFormat = (a: number): string => a?.toLocaleString() || "0";
export const unitFormat: { [key in UnitType]: (a: number) => string } = {
  count: (a) => baseFormat(a),
  // thousands: (a) => (a * 1000).toLocaleString(),
  kg: (a) => `${baseFormat(a)}kg`,
  lbs: (a) => `${baseFormat(a)}lbs`,
  km: (a) => `${baseFormat(a)}km`,
  miles: (a) => `${baseFormat(a)} miles`,
  seconds: (a) => `${baseFormat(a)} secs`,
  minutes: (a) => `${baseFormat(a)} mins`,
  hours: (a) => `${baseFormat(a)} hrs`,
  metres: (a) => `${baseFormat(a)}m`,
  feet: (a) => `${baseFormat(a)} feet`,
  yards: (a) => `${baseFormat(a)} yards`,
  calories: (a) => `${baseFormat(a)} calories`,
  stone: (a) => `${baseFormat(a)} stone`,
};

export const unitMini: { [key in UnitType]: string } = {
  count: "",
  // thousands: "000",
  kg: "kg",
  lbs: "lbs",
  km: "km",
  miles: "miles",
  seconds: "secs",
  minutes: "mins",
  hours: "hrs",
  metres: "m",
  feet: "ft",
  yards: "yards",
  calories: "cals",
  stone: "stone",
};
