import { useEffect, useState } from "react";
import { CellValuesType, RoutineItemType } from "./types";
import {
  classNames,
  getDayString,
  getScore,
  getValue,
  isDateAfterOrEqual,
} from "./utils";

const DayTotal = ({
  cellValues,
  day,
  items,
}: {
  cellValues: CellValuesType;
  day: Date;
  items: RoutineItemType[];
}) => {
  const [vals, setVals] = useState<CellValuesType>(cellValues);
  const maxScore = items
    .filter(
      (item) =>
        getValue(vals, item, day) !== false || // has a value
        !item.startDate || // or has no start date
        isDateAfterOrEqual(day, new Date(item.startDate)) // or start date is before today
    )
    .map((item) => 2 * item.importance)
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    const onChange = () => {
      const userData = localStorage.getItem("userData");
      if (userData) {
        // console.log("EVENT userData");
        setVals(JSON.parse(userData)[0].vals);
      }
    };
    const eventName = `cellChange-day-${getDayString(day)}`;
    // console.log("add listener", eventName);
    document.addEventListener(eventName, onChange);
    return () => document.removeEventListener(eventName, onChange);
  }, [day]);
  const total =
    (items
      .map((item) => (getScore(vals, item, day) + 1) * item.importance)
      .reduce((a, b) => a + b, 0) /
      maxScore) *
    10;
  return items.some((item) => getValue(vals, item, day) !== false) ? (
    <span
      className={classNames(
        items
          .filter(
            (item) =>
              !item.startDate ||
              isDateAfterOrEqual(day, new Date(item.startDate))
          )
          .every((item) => getValue(vals, item, day) !== false)
          ? classNames(
              "font-bold",
              total <= 3.5 ? "text-red-600 dark:text-red-500" : "",
              total >= 6.5 ? "text-green-600" : "",
              total > 3.5 && total < 6.5 ? "text-yellow-600" : ""
            )
          : "opacity-50 font-light",
        "text-lg font-['Poppins']"
      )}
      // title={JSON.stringify(
      //   theRoutine.map((item) => getValue(cellValues, item, d[3]))
      // )}
    >
      <span className="">{total.toFixed(1)}</span>
      {/* <span className="text-xs opacity-60">%</span> */}
    </span>
  ) : (
    <span>&nbsp;</span>
  );
};

export default DayTotal;
