import { classNames } from "./utils";

const Tooltip = ({
  text,
  position = "bottom",
  children,
  className = "",
}: {
  text: string;
  position: "top" | "bottom" | "left" | "right";
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
}) => {
  return (
    <span
      className={classNames(
        "relative inline-flex flex-col items-center justify-center group font-normal",
        className
      )}
    >
      {children}
      <span
        className={classNames(
          "absolute items-center hidden",
          text ? "group-hover:flex" : "",
          position === "bottom" ? "flex-col bottom-0 -mb-8" : "",
          position === "right" ? "flex-row left-full -ml-1" : "",
          className
        )}
      >
        {/* <div
          className={classNames(
            `w-3 h-3 rotate-45 bg-gray-100 dark:bg-gray-600`,
            position === "bottom" ? "-mb-2" : "",
            position === "right" ? "-mr-2x" : ""
          )}
        /> */}
        <span
          className={`relative z-10 p-2 text-xs leading-none text-gray-800 whitespace-no-wrap bg-gray-100 dark:bg-gray-600 dark:text-gray-50 shadow-lg rounded-lg whitespace-nowrap before:content-[attr(data-tooltip-text)]`}
          data-tooltip-text={text}
        />
      </span>
    </span>
  );
};

export default Tooltip;
