import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  Bars3Icon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { User } from "firebase/auth";

import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  // updateDoc,
  where,
} from "firebase/firestore";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  Navigate,
  // useNavigate,
  // useNavigate,
} from "react-router-dom";
// import { exampleRoutine } from "./example";
import { auth, db, logout } from "./firebase";
import Login from "./Login";
import PrivacyPolicy from "./PrivacyPolicy";
import Register from "./Register";
import Reset from "./Reset";
import FitbitLogoWhite from "./images/Fitbit_Symbol_White_RGB.png";
import FitbitLogoBlack from "./images/Fitbit_Symbol_Black_RGB.png";

import RoutineGrid from "./RoutineGrid";
import Button from "./Button";
import Auth from "./Auth";
import { getAuthorizeUrl } from "./fitbit";
// import fitbitBadgeWhite from "./images/Works_With_Fitbit_badge_horizontal_Updated_RGB_No_Reg_v2.png";
// import fitbitBadgeDark from "./images/Works_With_Fitbit_badge_horizontal_Updated_RGB_No_Reg_v1.png";
import { Menu, Transition } from "@headlessui/react";
import Landing from "./Landing";
import { classNames } from "./utils";
// import { getFitbitData, getFitness } from "./googlefitnew";
import { getFitAuthorizeUrl } from "./googlefitauth";
// import { useGoogleFitness } from "./googlefit";
// import { useGoogleFitness } from "./googlefit";
// import { RoutineItemType } from "./types";
// import { getWeekString } from "./utils";
// import { RoutineItemType } from "./types";

const AccessDenied = () => (
  <div className="w-screen text-center text-xl py-10 flex flex-col space-y-3">
    <div className="text-red-500">Access denied!</div>
    <div className="text-sm">
      We are currently in the invite-only Alpha phase, sorry. Please check back
      soon or{" "}
      <a
        href="mailto:info@mento.co.uk"
        className="text-teal-600 hover:text-teal-500 dark:text-teal-500 dark:hover:text-teal-400"
      >
        email us
      </a>{" "}
      to request access.
    </div>
  </div>
);
const LOADING_TEXT: { [k: string]: string } = {
  done: "Done!",
  loggedout: "Signed out!",
  loadingdata: "Loading Data...",
  loggingin: "Signing In...",
  other: "...",
};
const STATUS_ICON: { [k: string]: JSX.Element } = {
  done: (
    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
  ),
  loggedout: <XMarkIcon className="h-6 w-6 text-red-500" aria-hidden="true" />,
  loadingdata: (
    <ArrowPathIcon
      className="h-6 w-6 text-amber-500 animate-spin"
      aria-hidden="true"
    />
  ),
  loggingin: (
    <ArrowPathIcon
      className="h-6 w-6 text-amber-500 animate-spin"
      aria-hidden="true"
    />
  ),
  other: (
    <ExclamationCircleIcon
      className="h-6 w-6 text-red-400"
      aria-hidden="true"
    />
  ),
};
// if (!user) return "loggedout";
// if (user && userData && userData[0]?.vals) return "done";
// if (user && userData && !userData[0]?.vals) return "loadingdata";
// if (user && !userData) return "loggingin";
// return "other";
const Loading = ({ status = "done" }: { status: string }) => (
  <div className="w-full text-center text-xlx flex flex-col space-y-3">
    <div className=" text-amber-500x">{LOADING_TEXT[status] || "..."}</div>
    {/* {status === "loggedout" && (
      <Link to="/signin" className="text-sm underline hover:no-underline">
        Sign In?
      </Link>
    )} */}
  </div>
);

const App = () => {
  // try {
  const [userAuth, loading, error] = useAuthState(auth);
  // } catch (e) {

  // const [show, setShow] = useState(true);
  const [showStatus, setStatus] = useState<[boolean, string]>([false, ""]);

  const authorizeUrl = useMemo(getAuthorizeUrl, []);
  const authorizeUrlFit = useMemo(getFitAuthorizeUrl, []);
  console.log("authorizeUrlFit", authorizeUrlFit);

  // const [isFontLoaded, setIsFontLoaded] = useState(false);
  useEffect(() => {
    // document.documentElement.classList.add("hide");
    // document.fonts
    //   .load("96px Prompt")
    //   .then(() => document.documentElement.classList.remove("showFonts"));

    if ("fonts" in document) {
      Promise.all([
        document.fonts.load("16px Poppins"),
        document.fonts.load('16px "Open Sans"'),
      ]).then(() => {
        document.documentElement.classList.add("showFonts");
      });
    } else {
      // document.documentElement.classList.add("showFonts");
    }
  }, []);

  // }
  const [fetched, setFetched] = useState(false);
  const lSUD = localStorage.getItem("userData");
  const lsUA = localStorage.getItem("userAuth");
  const [userData, setUserData] = useState<
    [DocumentData, DocumentReference] | undefined
  >(
    typeof lSUD === "string" && lSUD !== "undefined"
      ? JSON.parse(lSUD)
      : undefined
  );
  console.log("userData", userData);

  if (error) console.error("App useAuthState error", error);
  // const [items, setItems] = useState<RoutineItemType[]>();
  // const navigate = useNavigate();
  const user: User | null | undefined =
    userAuth ||
    (typeof lsUA === "string" && lsUA !== "undefined"
      ? JSON.parse(lsUA)
      : undefined);
  // console.log("user", user, lsUA);
  useEffect(() => {
    // console.log("UPDATING userData localStorage");
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);
  useEffect(() => {
    if (userAuth) localStorage.setItem("userAuth", JSON.stringify(userAuth));
  }, [userAuth]);

  const fetchUserVals = useCallback(async () => {
    try {
      if (user?.uid) {
        console.log("fetchUserVals");
        // const q = query(collection(db, "vals"), where("uid", "==", user?.uid));
        // const doc = await getDocs(q);
        // const data = doc.docs[0].data();
        const docRef = doc(db, "vals", user.uid);
        const docSnap = await getDoc(docRef);
        // console.log("FETCH VALS", data);
        if (docSnap.exists()) {
          setUserData((u) =>
            u ? [{ ...u[0], vals: docSnap.data() }, u[1]] : undefined
          );
        } else {
          setUserData((u) => (u ? [{ ...u[0], vals: {} }, u[1]] : undefined));
        }
        setFetched(true);
      }
      // setUserData([data, doc.docs[0].ref]);
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  }, [user?.uid]);
  const fetchUserData = useCallback(async () => {
    try {
      console.log("fetchUserData", user?.uid);
      if (!user?.uid) return;
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      console.log("doc", doc, doc.empty);

      if (!doc.empty) {
        const data = doc.docs[0].data();
        // console.log("FETCH", data);
        setUserData((ud) => [
          { ...data, vals: (ud && ud[0] && ud[0].vals) || undefined },
          doc.docs[0].ref,
        ]);

        fetchUserVals();
      } else {
        console.log("fetchUserData EMPTY");
      }
    } catch (err) {
      console.error(err);
      // alert("An error occured while fetching user data");
    }
  }, [user?.uid, fetchUserVals]);

  // useEffect(() => {
  //   const checkItems = async () => {
  //     if (
  //       userData &&
  //       (!userData[0].routineItems ||
  //         userData[0].routineItems.length < exampleRoutine.length)
  //     ) {
  //       if (!userData[0].routineItems) {
  //         console.log("UPDATING routineItems");
  //         await updateDoc(userData[1], {
  //           routineItems: exampleRoutine,
  //         });
  //       }
  //     }
  //   };
  //   checkItems();
  // }, [userData]);
  // const fetchItems = useCallback(async () => {
  //   try {
  //     console.log("fetchItems", user?.uid);
  //     const q = query(
  //       collection(db, "routineItem"),
  //       where("uid", "==", user?.uid)
  //     );
  //     const querySnapshot = await getDocs(q);

  //     const items: RoutineItemType[] = [];
  //     querySnapshot.forEach((doc) => {
  //       // doc.data() is never undefined for query doc snapshots
  //       items.push(doc.data() as RoutineItemType);
  //     });
  //     console.log("FETCH ALL items ", items);
  //     setItems(items);
  //     // const data = doc.docs[0].data();
  //     // console.log("FETCH fetchItems", doc);
  //     // setUserData(data);
  //   } catch (err) {
  //     console.error(err);
  //     // alert("An error occured while fetching user data");
  //   }
  // }, [user?.uid]);

  useEffect(() => {
    if (!fetched) {
      setTimeout(fetchUserData, 1000);
    }
  }, [fetched, fetchUserData]);

  // useEffect(() => {
  // if (user && !items) fetchItems();
  // }, [user, items, fetchItems]);
  // console.log("user userData", user, userData);
  const [themeRefresh, setThemeRefresh] = useState<number>(0);
  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [themeRefresh]);
  // console.log("user.photoURL", JSON.stringify(user?.photoURL));

  // console.log(
  //   "STATUS",
  //   Boolean(user),
  //   Boolean(userData),
  //   Boolean(userData && userData[0]?.vals)
  // );
  const status = useMemo(() => {
    if (loading) return "loggingin";
    if (!user) return "loggedout";
    if (user && userData && userData[0]?.vals) return "done";
    if (user && userData && !userData[0]?.vals) return "loadingdata";
    if (user && !userData) return "loggingin";
    return "other";
  }, [loading, user, userData]);

  const statusTimer = useRef<NodeJS.Timeout | false>(false);
  const hasUpdatedStatusRef = useRef(0);
  useEffect(() => {
    // if (statusTimer.current !== false) clearTimeout(statusTimer.current);
    // if (loading) setStatus([true, "loggingin"]);
    // else if (!user) setStatus([true, "loggedout"]);
    // else if (user && userData && userData[0]?.vals) setStatus([true, "done"]);
    // else if (user && userData && !userData[0]?.vals)
    //   setStatus([true, "loadingdata"]);
    // else if (user && !userData) setStatus([true, "loggingin"]);
    // else {
    //   return;
    // }
    // console.log("uE status1", hasUpdatedStatusRef.current, status);
    if (hasUpdatedStatusRef.current < 3) {
      hasUpdatedStatusRef.current += 1;
      return;
    }
    // console.log("uE status2", status);
    setStatus([true, status]);

    statusTimer.current = setTimeout(
      () => setStatus((s) => [false, s[1]]),
      2000
    );
    // return "other";
  }, [status]);
  console.log("status", status);

  // console.log("status, fetched", status, fetched, loading, user, userData);
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return navigate("/signin");
  // }, [user, loading, navigate]);

  // console.log("getAuthorizeUrl", getAuthorizeUrl());
  const isAccessDenied = useMemo(
    () =>
      user &&
      userData &&
      userData[0].vals &&
      !["admin", "alpha"].includes(userData[0].status),
    [user, userData]
  );

  return (
    <Router>
      <div className="overflow-x-hidden">
        <div className="bg-white dark:bg-gray-800 text-black dark:text-gray-300 w-screen h-screen font-['Open_Sans'] flex flex-col justify-between">
          <header className="h-10 sticky top-0 z-50 bg-teal-600 text-white w-full flex flex-row justify-between items-center px-2 py-2">
            {/* <button
              type="button"
              className="rounded-full p-2 grow-0 text-gray-100 hover:bg-gray-50/10 duration-100"
            >
              <Bars3Icon className="h-5 w-5" aria-hidden="true" />
            </button> */}
            {user && userData ? (
              <Button icon={Bars3Icon} onClick={() => false} size="sm" />
            ) : (
              <div />
            )}
            <div className="font-['Poppins'] text-[16px]">
              <span className="font-bold">Daily Goals</span>
              <span className="hidden sm:inline font-light ml-1">
                Routine Tracker
              </span>
              <span className="rounded-xl px-1 text-xs bg-white/90 text-teal-600 ml-2 font-bold">
                ALPHA
              </span>
            </div>
            {user && userData ? (
              // <div className="flex flex-row space-x-2 mr-2">
              //   <span className="">{userData[0].name.split(" ")[0]}</span>
              //   <div className="w-6 h-6">
              //     {user?.photoURL && (
              //       <img
              //         className="w-6 h-6 rounded-full"
              //         referrerPolicy="no-referrer"
              //         src={user?.photoURL}
              //         // style={
              //         //   user.photoURL
              //         //     ? { backgroundImage: `url(${user?.photoURL})` }
              //         //     : {}
              //         // }
              //         alt="Avatar"
              //       />
              //     )}
              //   </div>
              // </div>
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open user menu</span>
                    <div className="w-6 h-6">
                      {user?.photoURL && (
                        <img
                          className="w-6 h-6 rounded-full border-2 border-white"
                          referrerPolicy="no-referrer"
                          src={user?.photoURL}
                          // style={
                          //   user.photoURL
                          //     ? { backgroundImage: `url(${user?.photoURL})` }
                          //     : {}
                          // }
                          alt="Avatar"
                        />
                      )}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-1 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 text-gray-700 dark:text-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {!isAccessDenied && (
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            disabled={!(status === "done" && fetched)}
                            onClick={fetchUserVals}
                            className={classNames(
                              "w-full flex flex-row items-center",
                              active ? "bg-gray-100 dark:bg-gray-700" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            <ArrowPathIcon
                              className={classNames(
                                "w-4 h-4 mr-3",
                                status === "done" && fetched
                                  ? ""
                                  : "animate-spin"
                              )}
                            />
                            <span>
                              {status === "done" && fetched
                                ? "Sync"
                                : "Synching"}
                            </span>
                          </button>
                        )}
                      </Menu.Item>
                    )}
                    {!isAccessDenied && userData[0].status === "admin" && (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to={authorizeUrl}
                            className={classNames(
                              "w-full flex flex-row items-center",
                              active ? "bg-gray-100 dark:bg-gray-700" : "",
                              "w-full block px-4 py-2 text-sm"
                            )}
                          >
                            <img
                              src={FitbitLogoWhite}
                              className="w-4 h-4 mr-3 hidden dark:block"
                              alt="Fitbit"
                            />
                            <img
                              src={FitbitLogoBlack}
                              className="w-4 h-4 mr-3 dark:hidden"
                              alt="Fitbit"
                            />
                            <span>Fitbit Auth</span>
                          </Link>
                        )}
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            if (
                              document.documentElement.classList.contains(
                                "dark"
                              )
                            )
                              localStorage.theme = "light";
                            else localStorage.theme = "dark";
                            setThemeRefresh((a) => a + 1);
                          }}
                          className={classNames(
                            "w-full flex flex-row items-center",
                            active ? "bg-gray-100 dark:bg-gray-700" : "",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          <SunIcon className="w-4 h-4 mr-3" />
                          <span>Switch Theme</span>
                        </button>
                      )}
                    </Menu.Item>
                    {/* <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={authorizeUrlFit}
                          className={classNames(
                            "w-full flex flex-row items-center",
                            active ? "bg-gray-100 dark:bg-gray-700" : "",
                            "w-full block px-4 py-2 text-sm"
                          )}
                        >
                          <img
                            src={FitbitLogoWhite}
                            className="w-4 h-4 mr-3 hidden dark:block"
                            alt="Fitbit"
                          />
                          <img
                            src={FitbitLogoBlack}
                            className="w-4 h-4 mr-3 dark:hidden"
                            alt="Fitbit"
                          />
                          <span>Google Fit Auth</span>
                        </Link>
                      )}
                    </Menu.Item> */}
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={logout}
                          className={classNames(
                            "w-full flex flex-row items-center",
                            active ? "bg-gray-100 dark:bg-gray-700" : "",
                            "text-left block px-4 py-2 text-sm"
                          )}
                        >
                          <ArrowRightOnRectangleIcon className="w-4 h-4 mr-3" />
                          <span>Sign Out</span>
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : (
              <div className="mr-2">
                {status !== "loggingin" && (
                  <Link className="mr-2 hover:opacity-60" to="/signin">
                    Sign In
                  </Link>
                )}
                {/* {" / "}

              <a className="ml-2" href="/register">
                Register
              </a> */}
              </div>
            )}
          </header>
          {isAccessDenied ? (
            <Routes>
              <Route index path="*" element={<AccessDenied />} />
            </Routes>
          ) : (
            <Routes>
              <Route
                index
                path="/"
                element={
                  !(user && userData && userData[0].vals) ? (
                    <Landing />
                  ) : (
                    <RoutineGrid
                      items={userData[0].routineItems}
                      // setItems={(items: RoutineItemType[]) => {
                      //   userData[0].routineItems = items;
                      // }}
                      userRef={userData[1]}
                      uid={user.uid}
                      cellValues={userData[0].vals || {}}
                    />
                  )
                }
              />

              <Route path="/signin" element={loading ? <div /> : <Login />} />
              <Route path="/reset" element={<Reset />} />
              <Route path="/register" element={<Register />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route
                path="/auth"
                element={
                  !(user && userData && userData[0].vals) ? (
                    <div />
                  ) : (
                    <Auth
                      type="fitbit"
                      uid={user?.uid || ""}
                      items={userData[0].routineItems}
                    />
                  )
                }
              />
              <Route
                path="/authFit"
                element={
                  !(user && userData && userData[0].vals) ? (
                    <div />
                  ) : (
                    <Auth
                      type="fit"
                      uid={user?.uid || ""}
                      items={userData[0].routineItems}
                    />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          )}

          {/* {status === "loggedout" && (
            <div className="w-full flex flex-row justify-center items-center">
              <img
                src={fitbitBadgeWhite}
                alt="Works with Fitbit"
                className="h-14 hidden dark:block"
              />
              <img
                src={fitbitBadgeDark}
                alt="Works with Fitbit"
                className="h-14 dark:hidden"
              />
            </div>
          )} */}
          <div className="mt-8 w-full flex flex-row justify-around items-center text-xs pb-4">
            <div>&copy; Mento Apps Ltd.</div>
            {!user && (
              <Button
                label="Switch theme"
                icon={SunIcon}
                variant="ghost"
                onClick={() => {
                  if (document.documentElement.classList.contains("dark"))
                    localStorage.theme = "light";
                  else localStorage.theme = "dark";
                  setThemeRefresh((a) => a + 1);
                }}
                size="sm"
              />
            )}
            {user && (
              <>
                {/* {!isAccessDenied && (
                  <Button
                    label={status === "done" && fetched ? "Sync" : "Synching"}
                    icon={ArrowPathIcon}
                    variant="ghost"
                    onClick={fetchUserVals}
                    size="sm"
                    disabled={!(status === "done" && fetched)}
                    iconClassName={
                      status === "done" && fetched ? "" : "animate-spin"
                    }
                  />
                )} */}
                {/* {!isAccessDenied && <Link to={authorizeUrl}>Fitbit Auth</Link>} */}
                {/* <Button
                  label="Google Fit"
                  onClick={useGoogleFitness}
                  size="xs"
                  variant="ghost"
                /> */}

                {/* <Button
                  label="Sign Out"
                  variant="ghost"
                  onClick={logout}
                  size="sm"
                /> */}
              </>
            )}
          </div>
        </div>
      </div>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 top-10 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showStatus[0]}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-900 dark:text-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {STATUS_ICON[showStatus[1]]}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <Loading status={showStatus[1]} />
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                      onClick={() => {
                        setStatus((s) => [false, s[1]]);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Router>
  );
};

export default App;
