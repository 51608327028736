import { useEffect, useState } from "react";
import { CellValuesType, RoutineItemType } from "./types";
import { getValue } from "./utils";

const ItemTotal = ({
  cellValues,
  item,
}: {
  cellValues: CellValuesType;
  item: RoutineItemType;
}) => {
  const [vals, setVals] = useState<CellValuesType>(cellValues);

  useEffect(() => {
    const onChange = () => {
      const userData = localStorage.getItem("userData");
      if (userData) {
        // console.log("EVENT userData");
        setVals(JSON.parse(userData)[0].vals);
      }
    };
    const eventName = `cellChange-item-${item.id}`;
    // console.log("add listener", eventName);
    document.addEventListener(eventName, onChange);
    return () => document.removeEventListener(eventName, onChange);
  }, [item]);

  const getDayBefore = (today: Date, daysBefore: number = 0) => {
    const day = new Date(today);
    day.setDate(day.getDate() - daysBefore);

    return day;
  };

  const getStreak = () => {
    const today = new Date();
    let streak = 0;
    const todayVal = getValue(vals, item, today);
    // console.log("getStreak todayVal", todayVal, item.name);
    if (todayVal === -1) return 0;
    else if (todayVal === 1) streak += 1;

    for (let daysBefore = 1; daysBefore < 30; daysBefore += 1) {
      const day = getDayBefore(today, daysBefore);
      const dayVal = getValue(vals, item, day);
      if (dayVal === 1) streak += 1;
      else return streak;
    }

    // console.log("getStreak", item.name, today, v);

    return streak;
  };

  const getPercentage = (): string => {
    const today = new Date();
    const values: number[] = [];
    // const todayVal = getValue(vals, item, today);

    for (let daysBefore = 0; daysBefore < 30; daysBefore += 1) {
      const day = getDayBefore(today, daysBefore);
      const dayVal = getValue(vals, item, day);
      if (dayVal) values.push(dayVal);
      // if (dayVal === 1) streak += 1;
      // else return streak;
    }

    return `${Math.round(
      (values.filter((v) => v === 1).length / values.length) * 100
    )}%`;

    // console.log("getStreak", item.name, today, v);

    // return streak;
  };

  const getDisplay = () => {
    const streak = getStreak();
    if (streak === 0) {
      return getPercentage();
    }
    return streak;
  };
  // console.log("ItemTotal", item.id, vals);
  return (
    <span>{["task", "thumb"].includes(item.type) ? getDisplay() : "-"}</span>
  );
};

export default ItemTotal;
