// import { HandThumbUpIcon as HandThumbUpOutlineIcon } from "@heroicons/react/24/outline";
// import {
//   HandThumbUpIcon,
//   HandThumbDownIcon,
//   // HandRaisedIcon,
// } from "@heroicons/react/24/outline";
// import { BiMeh, BiHappyAlt, BiAngry } from "react-icons/bi";
import {
  MdOutlineSentimentNeutral,
  MdSentimentVeryDissatisfied,
  MdSentimentVerySatisfied,
  MdOutlineSentimentSatisfied,
} from "react-icons/md";
// import { useEffect, useState } from "react";
import Tooltip from "./Tooltip";
import { RoutineItemType } from "./types";
// import { getEmojiUrl } from "./utils";

// type ValueType = -1 | 0 | 1;

const RoutineCellThumb = ({
  item,
  day,
  storedValue,
  storeValue,
}: {
  item: RoutineItemType;
  day?: Date;
  storedValue?: number;
  storeValue: (k: number | undefined) => void;
}) => {
  // const [value, setValue] = useState<number | undefined>(storedValue);

  // useEffect(() => {
  //   storeValue(value);
  // }, [value, storeValue]);

  const getTooltip = (v: number) => {
    if (v === 0) return "Not bad";
    if (v === 1) return "Great!";
    return "Bad";
  };

  return (
    <div
      className="h-[44px] min-w-[44px] flex justify-center items-center"
      onClick={
        () => {
          if (storedValue === undefined) storeValue(1);
          else if (storedValue === 1) storeValue(0);
          else if (storedValue === 0) storeValue(-1);
          else storeValue(undefined);
        }
        // setValue((v) => {
        //   if (v === undefined) return 1;
        //   if (v === 1) return -1;
        //   if (v === -1) return 0;
        //   return undefined;
        // })
      }
    >
      <Tooltip
        text={storedValue !== undefined ? getTooltip(storedValue) : ""}
        position="right"
        className="w-full"
      >
        <span>
          {storedValue === undefined && (
            // <img
            //   src={getEmojiUrl("1f590")}
            //   alt="Not set"
            //   className="w-5 h-5 grayscale"
            // />
            <MdOutlineSentimentSatisfied className="w-5 h-5 text-gray-300 dark:text-gray-700" />
          )}
          {storedValue === 1 && (
            <MdSentimentVerySatisfied className="w-5 h-5 text-green-600" />
          )}
          {storedValue === -1 && (
            <MdSentimentVeryDissatisfied className="w-5 h-5 text-red-600 dark:text-red-500/80" />
          )}
          {storedValue === 0 && (
            <MdOutlineSentimentNeutral className="w-5 h-5 text-amber-500 dark:text-amber-400/70" />
          )}
        </span>
      </Tooltip>
    </div>
  );
};

export default RoutineCellThumb;
