// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  getAuth,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  getRedirectResult,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
  deleteField,
} from "firebase/firestore";
import { RoutineItemType } from "./types";
import { getDayString, getWeekString, updateStorageCell } from "./utils";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCc5r6_3QsahBE-lgtBBplz-guvq_OpWuU",
  authDomain: "dailygoals.net", // "daily-goals-90366.firebaseapp.com",
  projectId: "daily-goals-90366",
  storageBucket: "daily-goals-90366.appspot.com",
  messagingSenderId: "501711249224",
  appId: "1:501711249224:web:4d9aac6bbbc93c6f949d7d",
  measurementId: "G-YZ541J3ND8",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();
googleProvider.addScope(
  "https://www.googleapis.com/auth/fitness.activity.read"
);
const facebookProvider = new FacebookAuthProvider();
const signInWithGoogle = async () => {
  try {
    // const res = await
    // googleProvider.setCustomParameters()
    await signInWithRedirect(auth, googleProvider);
    const user = auth.currentUser;
    console.log("signInWithGoogle user", user);
    // const token = user. user?.credential?.accessToken;
    // const user = res.user;
    // const q = query(collection(db, "users"), where("uid", "==", user.uid));
    // const docs = await getDocs(q);
    // if (docs.docs.length === 0) {
    //   await addDoc(collection(db, "users"), {
    //     uid: user.uid,
    //     name: user.displayName,
    //     authProvider: "google",
    //     email: user.email,
    //   });
    // }
  } catch (err) {
    console.error("google sign in error", err);
    // alert(err.message);
  }
};
const signInWithFacebook = async () => {
  try {
    signInWithRedirect(auth, facebookProvider);
  } catch (err) {
    console.error(err);
  }
};

const getGoogleResult = async () => {
  const auth = getAuth();

  getRedirectResult(auth)
    .then(async (result) => {
      if (result) {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          const token = credential.accessToken;
          if (token) localStorage.setItem("googleAccessToken", token);
          console.log("getGoogleResult token", token);

          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          const q = query(
            collection(db, "users"),
            where("uid", "==", user.uid)
          );
          const docs = await getDocs(q);
          if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
              uid: user.uid,
              name: user.displayName,
              authProvider: "google",
              email: user.email,
            });
          }
        }
      }
    })
    .catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.customData.email;
      // // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};
const getFacebookResult = async () => {
  const auth = getAuth();

  getRedirectResult(auth)
    .then(async (result) => {
      if (result) {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        if (credential) {
          // const token = credential.accessToken;

          // The signed-in user info.
          const user = result.user;
          // IdP data available using getAdditionalUserInfo(result)
          // ...
          const q = query(
            collection(db, "users"),
            where("uid", "==", user.uid)
          );
          const docs = await getDocs(q);
          if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
              uid: user.uid,
              name: user.displayName,
              authProvider: "google",
              email: user.email,
            });
          }
        }
      }
    })
    .catch((error) => {
      // Handle Errors here.
      // const errorCode = error.code;
      // const errorMessage = error.message;
      // // The email of the user's account used.
      // const email = error.customData.email;
      // // The AuthCredential type that was used.
      // const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

const logInWithEmailAndPassword = async (
  email: string,
  password: string,
  onError: (e: string) => void
) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error("sign in error", err);
    onError((err as any)?.code as string);
    //   alert(err.message);
  }
};

const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    //   alert(err.message);
  }
};
const sendPasswordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    console.log("Password reset link sent!");
  } catch (err) {
    console.error(err);
    //   alert(err.message);
  }
};
const logout = () => {
  console.log("logging out");
  localStorage.removeItem("userData");
  localStorage.removeItem("userAuth");
  signOut(auth);
};

const updateValue = async (
  uid: string,
  day: Date,
  item: RoutineItemType,
  v?: number
) => {
  await setDoc(
    doc(db, "vals", uid),
    {
      [getWeekString(day)]: {
        [getDayString(day)]: {
          [item.id]: v === undefined ? deleteField() : v,
        },
      },
    },
    { merge: true }
  );
  updateStorageCell(item, day, v);
};

export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  analytics,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getGoogleResult,
  signInWithFacebook,
  getFacebookResult,
  updateValue,
};
