import { TWEMOJI_BASE } from "./config";
import { CellValuesType, RoutineItemType } from "./types";
import { format, subDays } from "date-fns";
import { useEffect, useRef } from "react";

export const classNames = (...classes: any[]) => {
  return classes.filter(Boolean).join(" ");
};

export const isDateAfter = (d1: Date, d2: Date) =>
  d1.getFullYear() > d2.getFullYear() ||
  (d1.getFullYear() === d2.getFullYear() && d1.getMonth() > d2.getMonth()) ||
  (d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() > d2.getDate());

export const isDateAfterOrEqual = (d1: Date, d2: Date) =>
  d1.getFullYear() > d2.getFullYear() ||
  (d1.getFullYear() === d2.getFullYear() && d1.getMonth() > d2.getMonth()) ||
  (d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() >= d2.getDate());

export const getEmojiUrl = (code: string): string =>
  `${TWEMOJI_BASE}72x72/${code}.png`;

export const getWeekString = (day: Date) => format(day, "yyyy-II");
export const getDayString = (day: Date) => format(day, "yyyy-MM-dd");

export const getStorageKey = (item: RoutineItemType, day: Date) =>
  `${format(day, "yyyy-MM-dd")}-${item.id}`;

export const updateStorageCell = (
  item: RoutineItemType,
  day: Date,
  value?: string | number
) => {
  const lSUD = localStorage.getItem("userData");
  const userData =
    typeof lSUD === "string" && lSUD !== "undefined"
      ? JSON.parse(lSUD)
      : undefined;

  if (userData) {
    const dayString = getDayString(day);
    if (!userData[0].vals) userData[0].vals = {};
    if (!userData[0].vals[getWeekString(day)])
      userData[0].vals[getWeekString(day)] = {};
    if (!userData[0].vals[getWeekString(day)][dayString])
      userData[0].vals[getWeekString(day)][dayString] = {};

    userData[0].vals[getWeekString(day)][dayString][item.id] = value;
    localStorage.setItem("userData", JSON.stringify(userData));

    const itemEvent = new Event(`cellChange-item-${item.id}`);
    document.dispatchEvent(itemEvent);
    const dayEvent = new Event(`cellChange-day-${dayString}`);
    document.dispatchEvent(dayEvent);
    // console.log("call EVENT", `cellChange-day-${dayString}`);
  }

  // [getWeekString(day)]: {
  //   [getDayString(day)]: {
  //     [item.id]: v === undefined ? deleteField() : v,
  //   },
  // },
  // console.log("updateStorageCell userData", item, day, value, userData);
};

export const UNITS = [
  "count",
  "miles",
  "metres",
  "km",
  "feet",
  "yards",
  "seconds",
  "minutes",
  "hours",
  "lbs",
  "kg",
  "stone",
  "calories",
] as const;

export const getValue = (
  cellValues: CellValuesType,
  item: RoutineItemType,
  day: Date
): number | false => {
  const o = ((cellValues[getWeekString(day)] || {})[getDayString(day)] || {})[
    item.id
  ];

  if (o === undefined) return false;
  return o;
};
// Between -1 and +1
export const getScore = (
  cellValues: CellValuesType,
  item: RoutineItemType,
  day: Date
): number => {
  const val = getValue(cellValues, item, day);
  if (val === false) {
    if (item.type === "rating") return 0; // Default to 5/10
    if (item.type === "thumb") return 0; // Default to neutral
    return -1;
  }
  if (item.type === "task" || item.type === "thumb") return val;
  if (item.type === "rating")
    return (
      (Math.max(0, Math.min(item.invertScore ? 10 - val : val, 10)) - 5) / 5
    );
  if (item.type === "number") return checkTarget(cellValues, item, val, day);

  return -1;
};
export function isTouchDevice(): boolean {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}

export const postData = async (url = "", token = "", headers = {}) => {
  console.log("postData", url, `Bearer ${token}`);
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Origin": "*",
      // "Referrer-Policy": "no-referrer-when-downgrade",
      // "Cross-Origin-Opener-Policy": "same-origin-allow-popups",
      Authorization: `Bearer ${token}`,
      ...headers,
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //   body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  console.log("postData response", response);
  return response.json(); // parses JSON response into native JavaScript objects
};

export const roundHalf = (a: number) => Math.round(a * 2) / 2;

export const TYPE_EXAMPLES: { [k: string]: string } = {
  task: "Take medication",
  number: "Minutes of exercise",
  thumb: "Eating greens",
  rating: "Happiness",
};

export const getPreviousDays = (
  count: number,
  today: string = getDayString(new Date())
): Array<[string, string, string, Date]> => {
  const now = new Date(today);
  const dates = [...Array(count).keys()].map((n) => subDays(now, n));

  // formatDistance(subDays(new Date(), 3), new Date(), { addSuffix: true })
  return dates.map((d) => [
    format(d, "do"),
    format(d, "MMMM"),
    format(d, "EEE"),
    d,
  ]);
};
export const getMonthGroups = (
  days: Array<[string, string, string, Date]>
): Array<[string, number]> => {
  const counts: { [k: string]: number } = {};
  days.forEach((d) => {
    if (!counts[d[1]]) counts[d[1]] = 0;
    counts[d[1]]++;
  });

  return Object.entries(counts);
};

const getPreviousValue = (
  cellValues: CellValuesType,
  item: RoutineItemType,
  day: Date,
  limit: number = 20
): [number, Date] | false => {
  for (let s = 1; s <= limit; s++) {
    const d = subDays(day, s);
    const val = getValue(cellValues, item, d);
    if (val) return [val, d];
  }

  return false;
};

const getPreviousValues = (
  cellValues: CellValuesType,
  item: RoutineItemType,
  day: Date,
  searchLimit: number = 20,
  count: number = 10
): Array<[number, Date]> => {
  const vals: Array<[number, Date]> = [];
  let fromDay = day;
  while (vals.length < count) {
    const val = getPreviousValue(cellValues, item, fromDay, searchLimit);
    if (val === false) return vals;
    fromDay = val[1];
    vals.push(val);
  }

  return vals;
};

export const checkTarget = (
  cellValues: CellValuesType,
  item: RoutineItemType,
  value: number,
  day: Date
): -1 | 0 | 1 => {
  if (
    item.targetType === "gt" &&
    item.targetAmount &&
    typeof item.targetAmount === "number" &&
    value > item.targetAmount
  )
    return 1;
  if (
    item.targetType === "gte" &&
    item.targetAmount &&
    typeof item.targetAmount === "number" &&
    value >= item.targetAmount
  )
    return 1;
  if (
    item.targetType === "lt" &&
    item.targetAmount &&
    typeof item.targetAmount === "number" &&
    value < item.targetAmount
  )
    return 1;
  if (
    item.targetType === "lte" &&
    item.targetAmount &&
    typeof item.targetAmount === "number" &&
    value <= item.targetAmount
  )
    return 1;

  if (
    item.targetType === "gt" &&
    item.targetAmountNeutral &&
    typeof item.targetAmountNeutral === "number" &&
    value > item.targetAmountNeutral
  )
    return 0;
  if (
    item.targetType === "gte" &&
    item.targetAmountNeutral &&
    typeof item.targetAmountNeutral === "number" &&
    value >= item.targetAmountNeutral
  )
    return 0;
  if (
    item.targetType === "lt" &&
    item.targetAmountNeutral &&
    typeof item.targetAmountNeutral === "number" &&
    value < item.targetAmountNeutral
  )
    return 0;
  if (
    item.targetType === "lte" &&
    item.targetAmountNeutral &&
    typeof item.targetAmountNeutral === "number" &&
    value <= item.targetAmountNeutral
  )
    return 0;

  // Relative to previous amount
  if (item.targetAmount === "previous") {
    const prev = getPreviousValue(cellValues, item, day);
    if (prev === false) return 1;

    if (item.targetType === "gt" && value > prev[0]) return 1;
    if (item.targetType === "gte" && value >= prev[0]) return 1;
    if (item.targetType === "lt" && value < prev[0]) return 1;
    if (item.targetType === "lte" && value <= prev[0]) return 1;

    // return -1;
  }
  // Relative to average of X
  if (item.targetAmount === "average") {
    const prev = getPreviousValues(cellValues, item, day, 20, 10);
    if (prev.length === 0) return 1; // Nothing before, so it's good
    const average =
      prev.map((p) => p[0]).reduce((a, b) => a + b, 0) / prev.length;

    // console.log("item average", item, average, prev);

    if (item.targetType === "gt" && value > average) return 1;
    if (item.targetType === "gte" && value >= average) return 1;
    if (item.targetType === "lt" && value < average) return 1;
    if (item.targetType === "lte" && value <= average) return 1;

    // return -1;
  }

  // Relative to previous amount
  if (item.targetAmountNeutral === "previous") {
    const prev = getPreviousValue(cellValues, item, day);
    if (prev === false) return 1;

    if (item.targetType === "gt" && value > prev[0]) return 0;
    if (item.targetType === "gte" && value >= prev[0]) return 0;
    if (item.targetType === "lt" && value < prev[0]) return 0;
    if (item.targetType === "lte" && value <= prev[0]) return 0;

    // return -1;
  }
  // Relative to average of X
  if (item.targetAmountNeutral === "average") {
    const prev = getPreviousValues(cellValues, item, day, 20, 10);
    if (prev.length === 0) return 1; // Nothing before, so it's good
    const average =
      prev.map((p) => p[0]).reduce((a, b) => a + b, 0) / prev.length;

    // console.log("item average targetAmountNeutral", item, average, prev);

    if (item.targetType === "gt" && value > average) return 0;
    if (item.targetType === "gte" && value >= average) return 0;
    if (item.targetType === "lt" && value < average) return 0;
    if (item.targetType === "lte" && value <= average) return 0;

    // return -1;
  }

  if (item.targetType === "log") return 0;

  return -1;
};

export const useTraceUpdate = (
  props: { [k: string]: any },
  component: string
) => false;
export const useTraceUpdateX = (
  props: { [k: string]: any },
  component: string
) => {
  // console.log('LOADING useTraceUpdate for', component);
  const prev = useRef(props);
  useEffect(() => {
    // only in dev
    const changedProps = Object.entries(props).reduce((p, [k, v]) => {
      const ps: { [a: string]: any } = { ...p };
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log(component, "Changed props:", changedProps);
      // errorMsgs.push(`${component} Changed props: ${JSON.stringify(changedProps)}`);
    }
    prev.current = props;
  });
  return true;
};
