import { Dialog, RadioGroup } from "@headlessui/react";
import {
  CheckCircleIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import {
  MdOutlineSentimentNeutral,
  MdSentimentVeryDissatisfied,
  MdSentimentVerySatisfied,
} from "react-icons/md";
import { RoutineItemType, RoutineType } from "./types";
import { classNames, TYPE_EXAMPLES } from "./utils";
import RoutineItemForm from "./RoutineItemForm";
import Button from "./Button";

const getStartOfToday = () => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  return d;
};

const RoutineItemAdd = ({
  close,
  addItem,
}: {
  close: () => void;
  addItem: (i: RoutineItemType) => void;
}) => {
  // const [selectedTypeId, setSelectedTypeId] = useState("task");
  const [item, setItem] = useState<RoutineItemType>({
    id: 0,
    type: "task",
    name: "",
    emoji: "2705",
    importance: 5,
    startDate: getStartOfToday(),
  });
  const [step, setStep] = useState(1);
  const types = [
    {
      id: "task",
      title: "Task",
      description:
        "This is the simplest option, perfect for when a task is either done or not.",
      // example: "Take medication",
      options: [
        <CheckIcon key="task-opt-1" className="w-5 h-5 text-green-600" />,
        <XMarkIcon
          key="task-opt-2"
          className="w-5 h-5 text-red-600 dark:text-red-500"
        />,
      ],
    },
    {
      id: "number",
      title: "Number",
      description: "Enter a number each day, like in a spreadsheet.",
      // example: "Minutes of exercise",
      options: [],
    },
    {
      id: "thumb",
      title: "Satisfaction",
      description: "How you feel about something.",
      // example: "Eating greens",
      options: [
        <MdSentimentVerySatisfied
          key="thumb-opt-1"
          className="w-5 h-5 text-green-600"
        />,
        <MdOutlineSentimentNeutral
          key="thumb-opt-2"
          className="w-5 h-5 text-amber-500 dark:text-amber-400/70"
        />,
        <MdSentimentVeryDissatisfied
          key="thumb-opt-3"
          className="w-5 h-5 text-red-600 dark:text-red-500/80"
        />,
      ],
    },
    {
      id: "rating",
      title: "Rating",
      description: "Between 0 and 10.",
      // example: "Happiness",
      options: [],
    },
  ];

  const selectedType = types.find((t) => t.id === item.type);
  return (
    <div className="w-full">
      {step === 1 && (
        <div>
          <div className="mt-3">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6">
              Add Routine Item
            </Dialog.Title>
            {/* <div className="mt-2">
            <p className="text-sm opacity-80">
              First you must choose the type of Routine Item you wish to add.
              This is the only setting you can't change later (although you can
              just make a new one if you make a mistake).
            </p>
          </div> */}
          </div>
          <RadioGroup
            value={item.type}
            onChange={(type: RoutineType) => setItem((t) => ({ ...t, type }))}
          >
            {/* <RadioGroup.Label className="text-base font-semibold leading-6 ">
            Select a type
          </RadioGroup.Label> */}

            <div className="mt-4 grid grid-cols-1 gap-y-3">
              {types.map((type) => (
                <RadioGroup.Option
                  key={type.id}
                  value={type.id}
                  className={({ checked, active }) =>
                    classNames(
                      checked
                        ? "border-transparent"
                        : "border-gray-200 dark:border-gray-700",
                      active ? "border-teal-600 ring-2 ring-teal-600" : "",
                      "relative flex cursor-pointer rounded-lg border bg-transparent p-4 shadow-sm focus:outline-none"
                    )
                  }
                >
                  {({ checked, active }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <RadioGroup.Label
                            as="span"
                            className="flex flex-row space-x-2 text-sm font-medium "
                          >
                            <span>{type.title}</span>
                            {type.options}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="mt-1 flex items-center text-sm text-gray-500/90 dark:text-gray-300/80"
                          >
                            {type.description}
                          </RadioGroup.Description>
                          <RadioGroup.Description
                            as="span"
                            className="mt-1 flex items-center text-sm text-gray-500/90 dark:text-gray-300/80"
                          >
                            e.g. {TYPE_EXAMPLES[type.id]}
                          </RadioGroup.Description>
                          {/* <RadioGroup.Description
                          as="span"
                          className="mt-6 text-sm font-medium"
                        >
                          {type.options}
                        </RadioGroup.Description> */}
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(
                          !checked ? "invisible" : "",
                          "h-5 w-5 text-teal-600"
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          active ? "border" : "border-2",
                          checked ? "border-teal-600" : "border-transparent",
                          "pointer-events-none absolute -inset-px rounded-lg"
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      )}

      {step === 2 && selectedType && (
        <div className="">
          <div className="mt-0">
            <Dialog.Title
              as="h3"
              className="text-xl font-semibold leading-6 mb-4"
            >
              New '{selectedType.title}' Item
            </Dialog.Title>
            <RoutineItemForm
              type={selectedType.id as RoutineType}
              item={item}
              setItem={setItem}
            />
          </div>
        </div>
      )}
      <div className="mt-5 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2">
        {/* <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border dark:border-white dark:text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
          onClick={close}
        >
          Cancel
        </button> */}
        <Button
          label="Cancel"
          variant="outline"
          onClick={close}
          className=""
          fullWidth
        />

        {step === 2 ? (
          <Button
            label="Finish"
            onClick={() => {
              addItem(item);
              close();
            }}
            disabled={item.name.trim().length === 0}
            fullWidth
          />
        ) : (
          <Button
            label="Next"
            onClick={() => setStep((s) => s + 1)}
            fullWidth
          />
        )}
      </div>
    </div>
  );
};

export default RoutineItemAdd;
