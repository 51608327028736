import PKCE from "js-pkce";
// import { updateValue } from "./firebase";
import { RoutineItemType } from "./types";
import { getDayString, postData, roundHalf } from "./utils";
import ITokenResponse from "js-pkce/dist/ITokenResponse";

const CLIENT_ID = "23QWDK";
const pkce = new PKCE({
  client_id: CLIENT_ID,
  redirect_uri: `${window.location.origin}/auth`,
  authorization_endpoint: "https://www.fitbit.com/oauth2/authorize",
  token_endpoint: "https://api.fitbit.com/oauth2/token",
  requested_scopes:
    "activity cardio_fitness electrocardiogram heartrate nutrition profile respiratory_rate sleep weight",
});

const additionalParams = { response_type: "code" };
export const getAuthorizeUrl = () => pkce.authorizeUrl(additionalParams);

const handleFitbitResponse = (
  resp: ITokenResponse,
  onSuccess: (access: string, refresh: string) => void = () => false
) => {
  const accessToken = resp.access_token;
  const refreshToken = resp.refresh_token;
  const expiresIn = resp.expires_in;
  // Do stuff with the access token.

  if (accessToken) {
    localStorage.setItem("fitbitAccessToken", accessToken);
    localStorage.setItem("fitbitRefreshToken", refreshToken);
    localStorage.setItem(
      "fitbitExpiresAt",
      (new Date().getTime() + expiresIn * 1000).toString()
    );
    // console.log(
    //   "fitbitExpiresAt",
    //   (new Date().getTime() + expiresIn * 1000).toString()
    // );
    onSuccess(accessToken, refreshToken);
  }
};
const fitbitRefreshAuth = (
  fitbitRefreshToken: string,
  onSuccess: (access: string, refresh: string) => void = () => false
) => {
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: fitbitRefreshToken,
      client_id: CLIENT_ID,
    }),
  };

  fetch("https://api.fitbit.com/oauth2/token", params)
    .then((response) => response.json())
    .then((resp) => {
      console.log("fitbitRefreshAuth refresh success");
      // const accessToken = data.access_token;
      // console.log(accessToken);
      handleFitbitResponse(resp, onSuccess);
    })
    .catch((error) => {
      console.error(error);
    });
};
export const getFitbitAccessToken = (
  onSuccess: (access: string, refresh: string) => void = () => false
) => {
  const expiresAt = parseInt(localStorage.getItem("fitbitExpiresAt") || "0");
  console.log("LS fitbitExpiresAt", expiresAt);
  if (
    localStorage.getItem("fitbitAccessToken") &&
    new Date().getTime() < expiresAt
  ) {
    onSuccess(
      localStorage.getItem("fitbitAccessToken") || "",
      localStorage.getItem("fitbitRefreshToken") || ""
    );
    return;
  }
  const url = window.location.href;
  pkce
    .exchangeForAccessToken(url, additionalParams)
    .then((resp) => {
      handleFitbitResponse(resp, onSuccess);

      // console.log("access token XXX", token, resp);
    })
    .catch((e) => {
      console.error("ERROR exchangeForAccessToken", e);
      if (e?.error === "Invalid State") {
        const fitbitRefreshToken = localStorage.getItem("fitbitRefreshToken");
        if (fitbitRefreshToken) {
          // refresh
          console.log("refreshing token");
          fitbitRefreshAuth(fitbitRefreshToken, onSuccess);
        } else {
          window.location.href = getAuthorizeUrl();
        }
      }
    });
};

export const getDayData = async (
  day: Date,
  token = "",
  item: RoutineItemType
) => {
  const out: any = {};
  const date = getDayString(day);
  let data;
  if (item.fitbit === "sleep") {
    data = await postData(
      `https://api.fitbit.com/1/user/-/sleep/date/${date}.json`,
      token
    );
    // console.log("sleep data", data);
    if ((data as any).success === false) {
      console.error("fitbit postData fail", data);
      return out;
    }
    if (data.summary?.totalTimeInBed !== undefined)
      out.sleep =
        (data.summary?.totalTimeInBed || 0) - (data.summary?.stages?.wake || 0);
  }

  if (item.fitbit === "cardio" || item.fitbit === "steps") {
    data = await postData(
      `https://api.fitbit.com/1/user/-/activities/date/${date}.json`,
      token
    );
    if ((data as any).success === false) {
      console.error("fitbit postData fail", data);
      return out;
    }
    console.log("fitbit activity data", data);

    if (data.summary?.steps !== undefined) out.steps = data.summary?.steps;
    const cardio = data.summary?.heartRateZones?.filter(
      (z: any) => z.name === "Cardio"
    )[0]?.minutes;
    if (cardio !== undefined)
      out.cardio =
        (cardio || 0) +
        (data.summary?.heartRateZones?.filter((z: any) => z.name === "Peak")[0]
          ?.minutes || 0);
  }

  return out;
};

const formatFitbit = (item: RoutineItemType, data: any): number | undefined => {
  if (!item.fitbit) return 0;
  const d = data[item.fitbit];

  if (d === undefined) return undefined;

  if (item.fitbit === "sleep") {
    if (item.unit === "hours") return roundHalf(d / 60);
    return d;
  }

  if (item.fitbit === "cardio") {
    if (item.unit === "hours") return roundHalf(d / 60);
    return d;
  }

  if (item.fitbit === "steps") {
    if (item.thousands) return Math.round(d / 1000);
    return d;
  }

  return 0;
};

export const updateFitbitItem = async (
  uid: string,
  currentDay: Date,
  item: RoutineItemType,
  token: string,
  storeValue: (k: number | undefined) => void
) => {
  const data = await getDayData(currentDay, token, item);

  if (item.fitbit) {
    const formatted = formatFitbit(item, data);
    console.log("updateDay item", item, formatted);
    // updateValue(uid, currentDay, item, formatFitbit(item, data) || 0);
    if (formatted !== undefined) storeValue(formatted);
  }
};
