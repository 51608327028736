// import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { classNames } from "./utils";
import screenshotDark from "./images/screenshot-dark.png";
import screenshotLight from "./images/screenshot-light.png";
import fitbitBadgeDark from "./images/Works_With_Fitbit_badge_horizontal_Updated_RGB_No_Reg_v1.png";
import fitbitBadgeLight from "./images/Works_With_Fitbit_badge_horizontal_Updated_RGB_No_Reg_v2.png";
import googleFit from "./images/gfit_512dp.png";
import AppleHealthLogo from "./images/Apple_Health_badge_US-UK_blk_sRGB";

// const INTRO_TEXT = [
//   "Reach your goals, daily.",
//   "Quickly set your goals and track your daily routine. Whether it's sleep, exercise, diet or a simple task, you can easily keep up with your daily goals and track your progress.",
//   "Get started",
// ];
// Define the heading, body, and CTA label
// const heading = ["Transform Your Routine", "Set, Track, and Crush Your Goals!"];
// const body = [
//   "Positive change starts with small steps. With Daily Goals, you can make progress towards your goals every day. Our easy-to-use system helps you track your habits and achieve success in all areas of your life. Whether you want to improve your fitness, nutrition, or productivity, Daily Goals can help you get there.",
//   // "Join us today and take charge of your daily routine for a healthier, more productive, and fulfilling life!",
// ];
// const ctaLabel = "Get Started Now";

// Assign the heading, body, and CTA label to an array named INTRO_TEXT
// const INTRO_TEXT = [heading, body, ctaLabel];

const Landing = ({ className = "" }: { className?: string }) => {
  return (
    <div className={classNames("w-full", className)}>
      <div className="relative isolate pt-0">
        <svg
          className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true"
        >
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            className="fill-gray-300 dark:fill-black"
          />
        </svg>
        <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-20 dark:text-white">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
            <h1 className="mt-0 max-w-lg text-4xl font-bold font-['Poppins'] tracking-tight text-gray-900 dark:text-white sm:text-6xl">
              Transform Your Routine
            </h1>
            <h2 className="mt-6 max-w-lg text-2xl font-bold font-['Poppins'] tracking-tight text-gray-900 dark:text-white sm:text-3xl">
              Set, Track, and Crush Your Goals!
            </h2>
            <div className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
              Positive change starts with small steps. With{" "}
              <span className="text-teal-600 dark:text-teal-500">
                Daily Goals
              </span>
              , you can make progress towards your goals every day. Our
              easy-to-use system helps you track your habits and achieve success
              in all areas of your life. Whether you want to improve your
              fitness, nutrition, or productivity,{" "}
              <span className="text-teal-600 dark:text-teal-500">
                Daily Goals
              </span>{" "}
              can help you get there.
            </div>
            <div className="mt-10 flex flex-wrap items-center gap-x-6 gap-y-3">
              <Link
                to="/signin"
                className="rounded-md mr-6 text-lg bg-teal-600 px-3.5 py-2.5 font-bold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Get Started Now
              </Link>
              <img
                src={googleFit}
                alt="Works with Google Fit"
                title="Works with Google Fit"
                className="h-10"
              />
              <div>
                <img
                  src={fitbitBadgeLight}
                  alt="Works with Fitbit watches"
                  title="Works with Fitbit watches"
                  className="h-8 rounded-md hidden dark:block"
                />
                <img
                  src={fitbitBadgeDark}
                  alt="Works with Fitbit watches"
                  title="Works with Fitbit watches"
                  className="h-8 rounded-md dark:hidden"
                />
              </div>
              <div className="h-8" title="Works with Apple Health">
                <AppleHealthLogo className="h-full" />
              </div>
              {/* <Link to="/about" className="text-sm font-semibold leading-6">
                Learn more <span aria-hidden="true">→</span>
              </Link> */}
            </div>
          </div>
          <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
            <svg
              viewBox="0 0 366 729"
              role="img"
              className="mx-auto w-[22.875rem] max-w-full max-h-[calc(100vh_-_190px)] drop-shadow-xl"
            >
              <title>App screenshot</title>
              <defs>
                <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                  <rect width={316} height={684} rx={36} />
                </clipPath>
              </defs>
              <path
                fill="#4B5563"
                d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
              />
              <path
                fill="#343E4E"
                d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
              />
              <foreignObject
                width={316}
                height={684}
                transform="translate(24 24)"
                clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
              >
                <img
                  src={screenshotDark}
                  alt="Screenshot of Daily Goals"
                  className="hidden dark:block"
                />
                <img
                  src={screenshotLight}
                  alt="Screenshot of Daily Goals"
                  className="dark:hidden"
                />
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Landing;
