import { useCallback, useState, Fragment, useMemo, useEffect } from "react";
import { db } from "./firebase";
import update from "immutability-helper";
import {
  arrayUnion,
  // deleteField,
  // doc,
  DocumentReference,
  runTransaction,
  // setDoc,
  updateDoc,
} from "firebase/firestore";
import { unitMini } from "./config";
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  ChevronLeftIcon,
  PlusIcon,
  XMarkIcon,
  // ChevronRightIcon,
} from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import DraggableRow from "./DraggableRow";
import { CellValuesType, RoutineItemType } from "./types";
import Tooltip from "./Tooltip";
import {
  classNames,
  getDayString,
  getEmojiUrl,
  getMonthGroups,
  getPreviousDays,
  getWeekString,
  isTouchDevice,
  // updateStorageCell,
  useTraceUpdate,
} from "./utils";
import RoutineItemSettings from "./RoutineItemSettings";
import RoutineItemAdd from "./RoutineItemAdd";
import Button from "./Button";
import DayTotal from "./DayTotal";
import ItemTotal from "./ItemTotal";
import RoutineCell from "./RoutineCell";

const RoutineGrid = ({
  items,
  userRef,
  // userAuth,
  uid,
  cellValues,
}: // setItems,
{
  items: RoutineItemType[];
  userRef: DocumentReference;
  // userAuth: User,
  uid: string;
  cellValues: CellValuesType;
  // setItems: (i: RoutineItemType[]) => void;
}) => {
  // const [user, loading, error] = useAuthState(auth);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return navigate("/signin");
  // }, [user, loading, navigate]);
  const [showArchived, setShowArchived] = useState(false);
  const [archiveAlert, setArchiveAlert] = useState<number | false>(false);

  useEffect(() => {
    if (archiveAlert) setTimeout(() => setArchiveAlert(false), 5000);
  }, [archiveAlert]);

  // console.log("cellValues", cellValues);
  const formatItems = useCallback(
    (itemsToFormat: RoutineItemType[]) =>
      (itemsToFormat || []).filter((i) => showArchived || !i.archived),
    [showArchived]
  );

  const [theRoutine, setRoutine] = useState(formatItems(items)); // || exampleRoutine;

  useEffect(() => {
    console.log("uE setRoutine from items", items);
    setRoutine(formatItems(items));
  }, [items, formatItems]);

  // console.log(items);

  // useEffect(() => {
  //   if (loading) {
  //     // maybe trigger a loading screen
  //     console.log("LOADING");
  //     return;
  //   }
  //   if (!user) navigate("/signin");
  //   console.log("user", user);
  // }, [user, loading, navigate]);

  // console.log("RoutineGrid user", user);

  const [openAdd, setOpenAdd] = useState(false);
  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("gridCollapsed") === "yes"
  );

  useEffect(() => {
    localStorage.setItem("gridCollapsed", collapsed ? "yes" : "no");
  }, [collapsed]);
  // const [, setRefreshScore] = useState(0);

  const [itemViewOpen, setItemViewOpen] = useState<[boolean, number]>([
    false,
    0,
  ]);

  // const cancelButtonRef = useRef(null);
  const getDayCount = (c: boolean) =>
    Math.floor(
      Math.max(
        1,
        (window.innerWidth - ((c ? 48 : 166) + 48)) /
          (window.innerWidth > 800 ? 76 : 56)
      )
    );
  const dayCountCollapsed = getDayCount(true);
  const dayCountExpanded = getDayCount(false);
  console.log("dayCountCollapsed", dayCountCollapsed, dayCountExpanded);
  const [today, setToday] = useState(getDayString(new Date()));
  useEffect(() => {
    const interval = setInterval(() => {
      const newDay = getDayString(new Date());
      if (newDay !== today) setToday(newDay);
    }, 1000);
    return () => clearInterval(interval);
  }, [today]);
  const days = useMemo(
    () => getPreviousDays(dayCountCollapsed, today),
    [dayCountCollapsed, today]
  );

  const months = getMonthGroups(days);
  // console.log("daysm", days, months);

  // console.log("itemViewOpen", itemViewOpen);

  const openItem =
    (items || []).filter((r) => r.id === itemViewOpen[1])[0] || theRoutine[0];

  useTraceUpdate(
    {
      items,
      uid,
      cellValues,
      // userAuth,
      // loading,
      // error,
      collapsed,
      itemViewOpen,
      days,
    },
    "RoutineGrid"
  );

  const storeItem = async (item: RoutineItemType) => {
    try {
      const newRoutineItems = await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(userRef);
        if (!sfDoc.exists()) {
          throw new Error("User not found!");
        }

        const newItems: RoutineItemType[] = sfDoc.data().routineItems;
        const itemIndex = newItems.findIndex((i) => i.id === item.id);
        console.log("Updating routineItems index", itemIndex);
        if (newItems.length > 0 && itemIndex >= 0) {
          newItems[itemIndex] = item;
          transaction.update(userRef, {
            routineItems: newItems,
          });
          return newItems;
        } else {
          return Promise.reject(`Sorry! Item with ID '${item.id}' not found.`);
        }
      });
      console.log("newRoutineItems ", newRoutineItems);
      // setItems(newRoutineItems);
      setRoutine(formatItems(newRoutineItems));
      return newRoutineItems;
    } catch (e) {
      console.error("newRoutineItems ERROR", e);
    }
  };

  // console.log("theRoutine", theRoutine);

  const saveRoutine = useCallback(
    async (routineItems: RoutineItemType[]) => {
      await updateDoc(userRef, {
        routineItems,
      });
    },
    [userRef]
  );
  useEffect(() => {
    if (theRoutine && theRoutine.length > 0) saveRoutine(theRoutine);
  }, [theRoutine, saveRoutine]);

  // const moveRow = useCallback(
  //   (dragIndex: number, hoverIndex: number) => {
  //     console.log("moveRow");
  //     const dragRow = theRoutine[dragIndex];
  //     const newRows = [...theRoutine];
  //     newRows.splice(dragIndex, 1);
  //     newRows.splice(hoverIndex, 0, dragRow);
  //     setRoutine(newRows);

  //     const nums = theRoutine.map((a, i) => i);
  //     nums.splice(dragIndex, 1);
  //     nums.splice(hoverIndex, 0, dragIndex);
  //     console.log("newRows", newRows, nums);
  //   },
  //   [theRoutine]
  // );

  const moveRow = useCallback((dragIndex: number, hoverIndex: number) => {
    setRoutine((prevRoutine: RoutineItemType[]) => {
      const dragged = prevRoutine[dragIndex];
      console.log("dragged", dragged);
      const newOrder = update(prevRoutine, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragged],
        ],
      });
      return newOrder;
    });
  }, []);

  const addButton = (
    // <button
    //   type="button"
    //   onClick={() => setOpenAdd(true)}
    //   className={classNames(
    //     "border font-bold shadow-md flex flex-row items-center  rounded-full text-white bg-teal-600 hover:bg-teal-500 dark:hover:bg-teal-700 duration-200",
    //     collapsed ? "p-1 ml-1" : "py-2 px-3"
    //   )}
    // >
    //   <PlusIcon
    //     className={classNames(
    //       "h-5 w-5 duration-1000",
    //       collapsed ? "rotate-[720deg]" : "rotate-0"
    //     )}
    //     aria-hidden="true"
    //   />
    //   {!collapsed && <span className="mx-2 opacity-70">Add Item</span>}
    // </button>
    <Button
      label={!collapsed ? "Add Item" : undefined}
      icon={PlusIcon}
      iconClassName={classNames(
        "duration-1000",
        collapsed ? "rotate-[720deg]" : "rotate-0"
      )}
      variant="solid"
      size="md"
      // className="!rounded-full"
      onClick={() => setOpenAdd(true)}
    />
  );

  const collapseButton = (
    <Tooltip
      text={collapsed ? "Expand" : ""}
      position="right"
      className={classNames("z-40", collapsed ? "w-full" : "shrink-0")}
    >
      {/* <button
        type="button"
        onClick={() => setCollapsed((c) => !c)}
        className={classNames(
          "border dark:border-white font-bold shadow-md flex flex-row items-center  rounded-full text-whitex hover:bg-black/5 dark:hover:bg-white/10 duration-200",
          collapsed ? "p-1 ml-1" : "py-1 px-2"
        )}
      >
        <ChevronLeftIcon
          className={classNames(
            "h-5 w-5 duration-200",
            collapsed ? "rotate-180" : ""
          )}
          aria-hidden="true"
        />
        {!collapsed && <span className="mx-2 opacity-70x">Collapse</span>}
      </button> */}
      <Button
        onClick={() => setCollapsed((c) => !c)}
        variant="outline"
        size="sm"
        className={"!rounded-full"}
        label={!collapsed ? "Collapse" : ""}
        icon={ChevronLeftIcon}
        iconClassName={classNames(
          "duration-200",
          collapsed ? "rotate-180" : ""
        )}
      />
    </Tooltip>
  );
  const SHOW_MONTHS = false;
  return (
    <div>
      {(items || []).length === 0 ? (
        <div className="flex flex-col text-center p-8 my-16 items-center space-y-2">
          <h1 className="w-full mt-10 max-w-xl text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl">
            Build your routine
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-white">
            Get started by adding a routine items and set your{" "}
            <span className="italic">daily goals</span>.
          </p>
          <div className="pt-8">{addButton}</div>
        </div>
      ) : (
        <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
          <table className="table-auto w-full text-center">
            <thead className="bg-white dark:bg-gray-800">
              {SHOW_MONTHS && (
                <tr className="text-sm">
                  <th className="w-2 border-[6px] border-l-white dark:border-l-gray-800 border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800"></th>
                  <th
                    rowSpan={2}
                    className=" border-[6px] border-l-white dark:border-l-gray-800 border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800 px-2 py-1 text-left"
                  >
                    <div className="flex flex-col items-start justify-end font-normal text-xs h-full">
                      {collapseButton}
                    </div>
                  </th>
                  {months.map((m, mi) => (
                    <th
                      className="border-[6px] px-3 py-1 font-normal text-left border-white dark:border-gray-800"
                      key={m[0]}
                      colSpan={m[1] + (mi === months.length - 1 ? 1 : 0)}
                    >
                      {m[0]}
                    </th>
                  ))}
                </tr>
              )}
              <tr className="text-sm sticky top-9 bg-white dark:bg-gray-800 z-10">
                {/* <th className=" bg-white dark:bg-gray-800 z-30 border border-t-white dark:border-t-gray-800 border-l-white dark:border-l-gray-800 px-2 py-1 text-left">
              <button
                type="button"
                onClick={() => setCollapsed((c) => !c)}
                className="rounded-full p-1 text-gray-900 dark:text-gray-100 hover:bg-gray-400/10 dark:hover:bg-gray-50/10 duration-100"
              >
                <ChevronLeftIcon
                  className={classNames(
                    "h-5 w-5 duration-200",
                    collapsed ? "rotate-180" : ""
                  )}
                  aria-hidden="true"
                />
              </button>
            </th> */}
                <th className="w-2 border-[6px] border-l-white dark:border-l-gray-800 border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800"></th>
                {!SHOW_MONTHS && (
                  <th className=" border-[6px] border-l-white dark:border-l-gray-800 border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800 px-2 py-1 text-left">
                    {collapseButton}
                  </th>
                )}
                {days.map((d, di) => (
                  <th
                    key={d.join(" ")}
                    className={classNames(
                      "border-[6px] border-l-white dark:border-l-gray-800 border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800 px-2 py-1 min-w-[48px]",
                      di === 0
                        ? "bg-gray-100x dark:bg-gray-900x"
                        : "font-normal",
                      di >= dayCountExpanded && !collapsed ? "hidden" : ""
                    )}
                  >
                    <span className="flex flex-col">
                      <span className="text-xs">{d[2]}</span>
                      <span className="-mt-1">{d[0]}</span>
                    </span>
                  </th>
                ))}
                <th className="border-[6px] px-2 py-1 min-w-[48px] border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody className="border-collapse border-[6px] border-slate-300">
              {theRoutine.map((r, index) => (
                <DraggableRow
                  key={r.id}
                  id={r.id}
                  index={index}
                  moveRow={moveRow}
                >
                  {/* <div className=" select-none h-[35px]"> */}
                  {/* {collapsed ? (
              <td
                className="border text-center"
                // title={}
              >
                <span className="px-2 py-1 flex flex-row justify-center items-center">
                  <Tooltip
                    text={`${r.name}${
                      r.unit && unitMini[r.unit] ? ` (${unitMini[r.unit]})` : ""
                    }`}
                    position="right"
                    className="w-full"
                  >
                    <span className="w-full h-full block">
                      <img
                        src={getEmojiUrl(r.emoji)}
                        alt={r.name}
                        className="w-4 h-4 m-auto"
                      />
                    </span>
                  </Tooltip>
                </span>
              </td>
            ) : ( */}
                  <td
                    className={classNames(
                      "border-[6px] border-l-white dark:border-l-gray-800 bg-white dark:bg-gray-800 z-30 cursor-pointer h-[44px]",
                      collapsed ? "w-[51px]" : ""
                    )}
                    onClick={() => setItemViewOpen([true, r.id])}
                  >
                    <span
                      className={classNames(
                        "py-1 flex flex-row items-center",
                        collapsed
                          ? "justify-center w-full"
                          : "justify-start w-max shrink-0 px-2 space-x-2"
                      )}
                    >
                      <Tooltip
                        text={
                          collapsed
                            ? `${r.name}${
                                r.unit && unitMini[r.unit]
                                  ? ` (${unitMini[r.unit]})`
                                  : ""
                              }`
                            : ""
                        }
                        position="right"
                        className={classNames(
                          "",
                          collapsed ? "w-full" : "shrink-0"
                        )}
                      >
                        <img
                          src={getEmojiUrl(r.emoji)}
                          alt={r.name}
                          className={classNames(
                            "w-4 h-4 aspect-square",
                            collapsed ? "" : "ml-2"
                          )}
                        />
                      </Tooltip>
                      {!collapsed && (
                        <span className="text-sm whitespace-nowrap">
                          <span>{r.name}</span>
                          {r.unit && unitMini[r.unit] && (
                            <span className="text-xs ml-1 opacity-50">
                              ({unitMini[r.unit]})
                            </span>
                          )}
                          {r.thousands && (
                            <span className="text-xs ml-1 opacity-50">
                              (x1000)
                            </span>
                          )}
                        </span>
                      )}
                    </span>
                  </td>
                  {/* )} */}

                  {days.map((d, di) => (
                    <td
                      key={d.join(" ")}
                      className={classNames(
                        "border-[6px] dark:border-[#212c3b]",
                        di === 0
                          ? "bg-gray-100 dark:bg-gray-900 hover:bg-gray-200 dark:hover:bg-black"
                          : "hover:bg-gray-100 dark:hover:bg-gray-900",
                        di >= dayCountExpanded && !collapsed ? "hidden" : ""
                      )}
                    >
                      <div className=" cursor-pointer">
                        <RoutineCell
                          cellValues={cellValues}
                          item={r}
                          day={d[3]}
                          // setRefreshScore={setRefreshScore}
                          // userRef={userRef}
                          uid={uid}
                          // cellValues={cellValues}
                          cellValue={
                            ((cellValues[getWeekString(d[3])] || {})[
                              getDayString(d[3])
                            ] || {})[r.id]
                          }
                        />
                      </div>
                    </td>
                  ))}
                  <td className="border-[6px] opacity-50 border-r-white dark:border-r-gray-800 text-sm">
                    <ItemTotal cellValues={cellValues} item={r} />
                  </td>
                </DraggableRow>
              ))}
              <tr className=" select-none h-[55px] text-sm">
                <td className="border-[6px] border-white dark:border-gray-800" />
                <td
                  // rowSpan={2}
                  className="px-2 py-1 text-left border-[6px] border-white dark:border-gray-800 bg-whitex dark:bg-gray-800x border-b-whitex dark:border-b-gray-800x z-30"
                >
                  <Tooltip
                    text={collapsed ? "Add Item" : ""}
                    position="right"
                    className={classNames(
                      "z-40",
                      collapsed ? "w-full" : "shrink-0"
                    )}
                  >
                    {addButton}
                  </Tooltip>
                  {!collapsed && (items || []).length !== theRoutine.length && (
                    <Tooltip
                      text={showArchived ? "Hide Archived" : "Show Archived"}
                      position="right"
                      className={classNames(
                        "z-40",
                        collapsed ? "w-full" : "shrink-0"
                      )}
                    >
                      <Button
                        icon={
                          showArchived ? ArchiveBoxXMarkIcon : ArchiveBoxIcon
                        }
                        variant="ghost"
                        onClick={() => setShowArchived((a) => !a)}
                      />
                    </Tooltip>
                  )}
                </td>

                {days.map((d, di) => (
                  <td
                    key={d.join(" ")}
                    className={classNames(
                      "border-[6px] border-white dark:border-gray-800 p-0",
                      di >= dayCountExpanded && !collapsed ? "hidden" : ""
                      // di === 0 ? "bg-gray-100 dark:bg-gray-900" : ""
                    )}
                    // title={theRoutine
                    //   .map((item) => getScore(item, d[3]) + 1)
                    //   .join(", ")}
                  >
                    <DayTotal
                      cellValues={cellValues}
                      day={d[3]}
                      items={theRoutine}
                    />
                  </td>
                ))}
                <td className="border-[6px] border-white dark:border-gray-800">
                  &nbsp;
                </td>
              </tr>

              {/* <tr className=" select-none h-[35px] text-sm">
            <td
              colSpan={days.length + 1}
              className="border-[6px] border-r-white dark:border-r-gray-800"
            >
              &nbsp;
            </td>
          </tr> */}
            </tbody>
          </table>
        </DndProvider>
      )}

      <Transition.Root show={openAdd} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          onClose={() => setOpenAdd(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hiddenx rounded-lg bg-white dark:bg-gray-800 dark:text-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                  <RoutineItemAdd
                    close={() => setOpenAdd(false)}
                    addItem={async (newItem) => {
                      const id =
                        (items || []).length === 0
                          ? 1
                          : Math.max(...(items || []).map((i) => i.id)) + 1;
                      // console.log("newItem", { ...newItem, id });

                      // const q = query(collection(db, "users"), where("uid", "==", user?.uid));
                      // const doc = await getDocs(q);

                      await updateDoc(userRef, {
                        routineItems: arrayUnion({ ...newItem, id }),
                      });
                      // await setDoc(
                      //   doc(db, "vals", uid),
                      //   {
                      //     [getWeekString(day)]: {
                      //       [getDayString(day)]: {
                      //         [item.id]: v === undefined ? deleteField() : v,
                      //       },
                      //     },
                      //   },
                      //   { merge: true }
                      // );
                    }}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={itemViewOpen[0]} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40"
          // initialFocus={cancelButtonRef}
          onClose={() => setItemViewOpen((a) => [false, a[1]])}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 dark:text-white text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg">
                  <RoutineItemSettings
                    item={openItem}
                    close={() => setItemViewOpen((a) => [false, a[1]])}
                    save={async (
                      item: RoutineItemType,
                      showArchiveAlert = false
                    ) => {
                      console.log(item, showArchiveAlert);
                      const newRoutineItems = await storeItem(item);

                      if (showArchiveAlert && newRoutineItems)
                        setArchiveAlert(item.id);
                    }}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50 top-12"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={Boolean(archiveAlert)}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-600 shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-center">
                  <div className="flex w-0 flex-1 justify-between">
                    <p className="w-0 flex-1 text-sm font-medium text-gray-900 dark:text-white">
                      Item archived
                    </p>
                    <button
                      type="button"
                      className="ml-3 flex-shrink-0 rounded-md text-sm font-medium text-teal-600 dark:text-teal-400 hover:text-teal-500 dark:hover:text-teal-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                      onClick={async () => {
                        // restore

                        const newRoutineItems = await storeItem(openItem);
                        if (newRoutineItems) setArchiveAlert(false);
                      }}
                    >
                      Undo
                    </button>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                      onClick={() => {
                        setArchiveAlert(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default RoutineGrid;
