import { Bars2Icon } from "@heroicons/react/20/solid";
import React from "react";
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd";

interface DraggableRowProps {
  id: any;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
  children: React.ReactNode;
}

interface DragItem {
  id: any;
  index: number;
}

const DraggableRow: React.FC<DraggableRowProps> = ({
  id,
  index,
  moveRow,
  children,
}) => {
  const ref = React.useRef<HTMLTableRowElement>(null);

  const [, drop] = useDrop({
    accept: "row",
    hover: (item: DragItem, monitor: DropTargetMonitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      // console.log("hover", dragIndex, hoverIndex);

      if (dragIndex === hoverIndex) return;

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: "row",
    item: { id, index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    // collect: (monitor) => ({
    //   opacity: monitor.isDragging() ? 0.4 : 1,
    // }),
  }));
  const opacity = isDragging ? 0 : 1;

  // drag(drop(ref));
  preview(drop(ref));

  // const opacity = isDragging ? 0 : 1;

  const handle = (
    <td
      className=" cursor-grab active:cursor-grabbing px-2 border-[6px] border-l-white dark:border-l-gray-800 border-r-white dark:border-r-gray-800 border-t-white dark:border-t-gray-800 opacity-50 hover:opacity-100"
      ref={drag}
    >
      <Bars2Icon className="w-4 h-4" />
    </td>
  );
  return (
    <tr
      // ref={(node) => preview(drop(node ? ref.current : null))}
      ref={ref}
      style={{ opacity }}
    >
      {/* <caption className="w-3 h-3 bg-black cursor-move" /> */}
      {handle}
      {children}
    </tr>
  );
};

export default DraggableRow;
