import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";
// import "./Register.css";
const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  if (error) console.error("Register useAuthState error", error);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);
  return (
    <div className="max-w-sm p-4 mx-auto min-h-[calc(100%_-_100px)] flex flex-row justify-center items-center">
      <div className="flex flex-col space-y-2">
        <input
          type="text"
          className="rounded-sm"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <input
          type="email"
          className="rounded-sm"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="rounded-sm"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="w-full bg-green-700 text-white rounded-sm py-2 my-1"
          onClick={register}
        >
          Register
        </button>
        <button
          className="w-full bg-black text-white rounded-sm py-2 my-1"
          onClick={signInWithGoogle}
        >
          Register with Google
        </button>
        <div>
          Already have an account?{" "}
          <Link to="/signin" className="underline hover:no-underline">
            Login
          </Link>{" "}
          now.
        </div>
      </div>
    </div>
  );
};
export default Register;
