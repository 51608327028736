import { KeyboardEvent, useState } from "react";
import { unitFormat, unitMini } from "./config";
import Tooltip from "./Tooltip";
import { CellValuesType, RoutineItemType } from "./types";
import { checkTarget, classNames } from "./utils";
import FitbitLogoWhite from "./images/Fitbit_Symbol_White_RGB.png";
import FitbitLogoBlack from "./images/Fitbit_Symbol_Black_RGB.png";
import { getFitbitAccessToken, updateFitbitItem } from "./fitbit";

const RoutineCellNumber = ({
  uid,
  cellValues,
  item,
  day,
  storedValue,
  storeValue,
}: {
  uid: string;
  cellValues: CellValuesType;
  item: RoutineItemType;
  day: Date;
  storedValue?: number;
  storeValue: (k: number | undefined) => void;
}) => {
  // const [value, setValue] = useState<number | undefined>(storedValue);

  // useEffect(() => {
  //   storeValue(value);
  // }, [value, storeValue]);
  const [loading, setLoading] = useState(false);

  const [editing, setEditing] = useState<[boolean, number | undefined]>([
    false,
    0,
  ]);
  const status =
    storedValue !== undefined
      ? checkTarget(cellValues, item, storedValue, day)
      : 0;
  const editingStatus =
    editing[1] !== undefined
      ? checkTarget(cellValues, item, editing[1], day)
      : 0;
  // console.log("value", value);
  // if (editing) {
  //   return (
  //     <div className="h-[31px] relative">

  //     </div>
  //   );
  // }

  const formatted: string =
    storedValue !== undefined && item.unit !== undefined
      ? unitFormat[item.unit](storedValue * (item.thousands ? 1000 : 1))
      : storedValue?.toLocaleString() || "";
  return (
    <div
      className={classNames(
        "h-[44px] min-w-[44px] leading-[43px] relative",
        item.fitbit && (storedValue === undefined || storedValue === null)
          ? "cursor-pointer"
          : "cursor-text",
        status === 1 ? "text-green-600 font-semibold" : "",
        status === -1 ? "text-red-600 dark:text-red-500 font-semibold" : "",
        status === 0 ? "text-amber-500 dark:text-amber-300" : ""
      )}
      onClick={
        item.fitbit && (storedValue === undefined || storedValue === null)
          ? () => {
              setLoading(true);
              console.log("calling getFitbitAccessToken");
              getFitbitAccessToken(async (token) => {
                console.log("got getFitbitAccessToken", token);
                updateFitbitItem(uid, day, item, token, (v) => {
                  storeValue(v);
                  setLoading(false);
                });
              });
            }
          : () => setEditing([true, storedValue])
      }
    >
      {editing[0] && (
        <div
          className="h-full min-w-[100px] absolute top-0 left-0 z-20"
          style={
            editing[1] === undefined
              ? {}
              : {
                  width: `calc(${editing[1].toString().length}ch + 30px${
                    item.unit && unitMini[item.unit] ? " + 3rem" : ""
                  })`,
                }
          }
        >
          <input
            type="number"
            className={classNames(
              "w-full h-full dark:bg-gray-800",
              item.unit && unitMini[item.unit] ? "pr-12" : "",
              editingStatus === 1 ? "text-green-600" : "",
              editingStatus === -1 ? "text-red-600 dark:text-red-500" : "",
              editingStatus === 0 ? "text-amber-500 dark:text-amber-300" : ""
            )}
            value={editing[1] === undefined ? "" : editing[1]}
            onChange={(e) =>
              setEditing([
                true,
                !Number.isNaN(parseFloat(e.target.value))
                  ? Math.max(0, parseFloat(e.target.value))
                  : undefined,
              ])
            }
            onBlur={() => {
              storeValue(editing[1]);
              setEditing([false, 0]);
            }}
            autoFocus
            onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Enter" || event.keyCode === 13) {
                event.preventDefault();

                (event.target as HTMLInputElement).blur();
              } else if (event.key === "Escape" || event.keyCode === 27) {
                (event.target as HTMLInputElement).blur();
              }
            }}
          />

          {item.unit && unitMini[item.unit] && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                {unitMini[item.unit]}
              </span>
            </div>
          )}
        </div>
      )}
      <Tooltip
        text={
          formatted &&
          formatted !== storedValue?.toLocaleString() &&
          !editing[0]
            ? formatted
            : ""
        }
        position="right"
        className="w-full"
      >
        <span
          className={classNames(
            "px-2",
            storedValue !== undefined &&
              storedValue !== null &&
              storedValue?.toLocaleString().length > 4
              ? "text-xs"
              : ""
          )}
        >
          {storedValue === undefined || storedValue === null
            ? "\u00A0"
            : storedValue?.toLocaleString()}
          {(storedValue === undefined || storedValue === null) &&
            item.fitbit && (
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-gray-800 dark:text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : (
                  <>
                    <img
                      src={FitbitLogoWhite}
                      alt="Fitbit"
                      className="w-5 h-5 hidden dark:block"
                    />
                    <img
                      src={FitbitLogoBlack}
                      alt="Fitbit"
                      className="w-5 h-5 dark:hidden"
                    />
                  </>
                )}
              </div>
            )}
        </span>
      </Tooltip>
    </div>
  );
};

export default RoutineCellNumber;
