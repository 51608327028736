import PKCE from "js-pkce";
import ITokenResponse from "js-pkce/dist/ITokenResponse";

const CLIENT_ID =
  "501711249224-vst721dutmbg64vkvnqk6an8vutg2f6u.apps.googleusercontent.com";
const REDIRECT_URI = `${window.location.origin}/authFit`;
const FIT_SCOPE = "https://www.googleapis.com/auth/fitness.activity.read";
const AUTH_ENDPOINT = "https://accounts.google.com/o/oauth2/v2/auth";
const TOKEN_ENDPOINT = "https://oauth2.googleapis.com/token";
const pkce = new PKCE({
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  authorization_endpoint: AUTH_ENDPOINT,
  token_endpoint: TOKEN_ENDPOINT,
  requested_scopes: FIT_SCOPE,
});

const additionalParams = {
  response_type: "token",
  access_type: "offline",
  state: "testing",
};
// export const getFitAuthorizeUrl = () => pkce.authorizeUrl(additionalParams);
// const generateRandomString = () => {
//   const array = new Uint8Array(16);
//   window.crypto.getRandomValues(array);
//   return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
// }
// const codeVerifier = generateRandomString();
// const generateCodeChallenge = (verifier) => {
//   const hashed = CryptoJS.SHA256(verifier);
//   const base64Url = CryptoJS.enc.Base64.stringify(hashed)
//     .replace(/=/g, '')
//     .replace(/\+/g, '-')
//     .replace(/\//g, '_');
//   return base64Url;
// }
// const hashedVerifier = await pkce.generateHashedVerifier(codeVerifier, {nonce: generateRandomString()});

export const getFitAuthorizeUrl = () =>
  pkce.authorizeUrl({
    authorizationEndpoint: "https://accounts.google.com/o/oauth2/v2/auth",
    clientId: CLIENT_ID,
    redirectUri: "http://localhost:3000/authFit",
    scope: "https://www.googleapis.com/auth/fitness.activity.read",
    // codeChallengeMethod: "S256",
    // access_type: "offline",
    // state: "testing",
    // responseType: "token",
    response_type: "token",
    code_challenge_method: "S256",
    code_challenge: "E9Melhoa2OwvFrEMTJguCHaoeK1t8URWbuGJSstw",
  });

const handleResponse = (
  resp: ITokenResponse,
  onSuccess: (access: string, refresh: string) => void = () => false
) => {
  const accessToken = resp.access_token;
  const refreshToken = resp.refresh_token;
  // const expiresIn = resp.expires_in;
  // Do stuff with the access token.

  if (accessToken) {
    // localStorage.setItem("fitbitAccessToken", accessToken);
    // localStorage.setItem("fitbitRefreshToken", refreshToken);
    // localStorage.setItem(
    //   "fitbitExpiresAt",
    //   (new Date().getTime() + expiresIn * 1000).toString()
    // );
    // console.log(
    //   "fitbitExpiresAt",
    //   (new Date().getTime() + expiresIn * 1000).toString()
    // );
    console.log("handleResponse");
    onSuccess(accessToken, refreshToken);
  }
};

export const getGoogleFitAccessToken = (
  onSuccess: (access: string, refresh: string) => void = () => false
) => {
  // const expiresAt = parseInt(localStorage.getItem("fitbitExpiresAt") || "0");
  // console.log("LS fitbitExpiresAt", expiresAt);
  // if (
  //   localStorage.getItem("fitbitAccessToken") &&
  //   new Date().getTime() < expiresAt
  // ) {
  //   onSuccess(
  //     localStorage.getItem("fitbitAccessToken") || "",
  //     localStorage.getItem("fitbitRefreshToken") || ""
  //   );
  //   return;
  // }
  const url = window.location.href;
  console.log("getGoogleFitAccessToken", url);
  pkce
    .exchangeForAccessToken(url, additionalParams)
    .then((resp) => {
      console.log("Fit exchangeForAccessToken then resp", resp);
      handleResponse(resp, onSuccess);

      // console.log("access token XXX", token, resp);
    })
    .catch((e) => {
      console.error("ERROR exchangeForAccessToken Fit", e);
      // if (e?.error === "Invalid State") {
      //   const fitbitRefreshToken = localStorage.getItem("fitbitRefreshToken");
      //   if (fitbitRefreshToken) {
      //     // refresh
      //     console.log("refreshing token");
      //     handleResponse(fitbitRefreshToken, onSuccess);
      //   } else {
      //     window.location.href = getAuthorizeUrl();
      //   }
      // }
    });
};
