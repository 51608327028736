import { useEffect } from "react";
// import { updateValue } from "./firebase";
import { getFitbitAccessToken } from "./fitbit";
import { RoutineItemType } from "./types";
import { getGoogleFitAccessToken } from "./googlefitauth";
// import { roundHalf } from "./utils";
// import { getDayString } from "./utils";

const Auth = ({
  uid,
  items,
  type,
}: {
  uid: string;
  items: RoutineItemType[];
  type: string;
}) => {
  //   const searchParams = new URLSearchParams(window.location.search);

  //   const code = searchParams.get("code");
  //   const state = searchParams.get("state");

  //   console.log("Auth", code, state);

  // const updateDay = useCallback(
  //   async (currentDay: Date, token: string) => {
  //     const data = await getDayData(currentDay, token);

  //     console.log("getDayData", currentDay, data);

  //     items.forEach((item) => {
  //       if (item.fitbit) {
  //         console.log("updateDay item", item, formatFitbit(item, data) || 0);
  //         updateValue(uid, currentDay, item, formatFitbit(item, data) || 0);
  //       }
  //     });
  //   },
  //   [items, uid]
  // );

  useEffect(() => {
    if (type === "fitbit") {
      if (!items.some((i) => i.fitbit)) return; // No fitbit items
      getFitbitAccessToken(async (token, refreshToken) => {
        //   const currentDay = new Date();
        // console.log("got token", token);
        // const today = new Date();
        // const yesterday = new Date(today);
        // const dayBefore = new Date(today);
        // const dayBeforeThat = new Date(today);

        // yesterday.setDate(yesterday.getDate() - 1);
        // dayBefore.setDate(dayBefore.getDate() - 2);
        // dayBeforeThat.setDate(dayBeforeThat.getDate() - 3);

        // await updateDay(today, token);
        // await updateDay(yesterday, token);
        // await updateDay(dayBefore, token);
        // await updateDay(dayBeforeThat, token);

        window.location.href = "/";
      });
    } else if (type === "fit") {
      console.log("Google Fit");
      const token = window.location.hash.substring(1);
      const params = new URLSearchParams(token);
      const accessToken = params.get("access_token");

      console.log("access_token", accessToken);

      // getGoogleFitAccessToken(async (token, refreshToken) => {
      //   //   const currentDay = new Date();
      //   // console.log("got token", token);
      //   // const today = new Date();
      //   // const yesterday = new Date(today);
      //   // const dayBefore = new Date(today);
      //   // const dayBeforeThat = new Date(today);

      //   // yesterday.setDate(yesterday.getDate() - 1);
      //   // dayBefore.setDate(dayBefore.getDate() - 2);
      //   // dayBeforeThat.setDate(dayBeforeThat.getDate() - 3);

      //   // await updateDay(today, token);
      //   // await updateDay(yesterday, token);
      //   // await updateDay(dayBefore, token);
      //   // await updateDay(dayBeforeThat, token);

      //   console.log("getGoogleFitAccessToken response", token, refreshToken);

      //   // window.location.href = "/";
      // });

      // const CLIENT_ID =
      //   "501711249224-vst721dutmbg64vkvnqk6an8vutg2f6u.apps.googleusercontent.com";
      // const REDIRECT_URI = `${window.location.origin}/authFit`;

      // // Exchange the authorization code for an access token.
      // const urlParams = new URLSearchParams(window.location.search);

      // const code = urlParams.get("code");
      // if (code) {
      //   // Set the token endpoint URL and parameters.
      //   const tokenEndpoint = "https://oauth2.googleapis.com/token";
      //   const tokenParams = new URLSearchParams({
      //     code: code,
      //     client_id: CLIENT_ID,
      //     redirect_uri: REDIRECT_URI,
      //     grant_type: "authorization_code",
      //   });

      //   // Send a POST request to the token endpoint to exchange the authorization code for an access token.
      //   fetch(tokenEndpoint, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     },
      //     body: tokenParams,
      //   })
      //     .then((response) => response.json())
      //     .then((data) => {
      //       const accessToken = data.access_token;
      //       console.log("getFitness accessToken", accessToken, data);
      //       // Use the access token to make authorized requests to the Google Fit API.
      //     })
      //     .catch((error) => {
      //       console.error(
      //         "Error exchanging authorization code for access token:",
      //         error
      //       );
      //     });

      // // Exchange the authorization code for an access token.
      // if (authorizationCode) {
      //   const CLIENT_ID =
      //     "501711249224-vst721dutmbg64vkvnqk6an8vutg2f6u.apps.googleusercontent.com";
      //   const REDIRECT_URI = `${window.location.origin}/authFit`;
      //   const FIT_SCOPE =
      //     "https://www.googleapis.com/auth/fitness.activity.read";
      //   const AUTH_ENDPOINT = "https://accounts.google.com/o/oauth2/v2/auth";
      //   const TOKEN_ENDPOINT = "https://oauth2.googleapis.com/token";
      //   const requestBody = new URLSearchParams({
      //     code: authorizationCode,
      //     client_id: CLIENT_ID,
      //     redirect_uri: REDIRECT_URI,
      //     grant_type: "authorization_code",
      //   });

      //   fetch(TOKEN_ENDPOINT, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     },
      //     body: requestBody,
      //   })
      //     .then((response) => response.json())
      //     .then((data) => {
      //       const accessToken = data.access_token;
      //       console.log("getFitness accessToken", accessToken, data);
      //       // Use the access token to make authorized requests to the Google Fit API.
      //     })
      //     .catch((error) => {
      //       console.error(
      //         "Error exchanging authorization code for access token:",
      //         error
      //       );
      //     });

      // const authCode = urlParams.get("code");

      // if (authCode) {
      //   const CLIENT_ID =
      //     "501711249224-vst721dutmbg64vkvnqk6an8vutg2f6u.apps.googleusercontent.com";
      //   const REDIRECT_URI = `${window.location.origin}/authFit`;
      //   const FIT_SCOPE =
      //     "https://www.googleapis.com/auth/fitness.activity.read";
      //   const AUTH_ENDPOINT = "https://accounts.google.com/o/oauth2/v2/auth";
      //   const TOKEN_ENDPOINT = "https://oauth2.googleapis.com/token";
      //   const data = new URLSearchParams({
      //     grant_type: "authorization_code",
      //     code: authCode,
      //     client_id: CLIENT_ID,
      //     redirect_uri: REDIRECT_URI,
      //   });

      //   fetch(TOKEN_ENDPOINT, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     },
      //     body: data,
      //   })
      //     .then((response) => response.json())
      //     .then((data) => {
      //       const accessToken = data.access_token;
      //       console.log("getFitness accessToken", accessToken);
      //       // Use the access token to make authorized requests to the Google Fit API.
      //     })
      //     .catch((error) => {
      //       console.error(
      //         "Error exchanging authorization code for access token:",
      //         error
      //       );
      //     });
    }
  }, [items, type]);

  return <div className="text-center w-full">Loading...</div>;
};
export default Auth;
