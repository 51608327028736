// import { Dialog } from "@headlessui/react";
import { ArchiveBoxIcon, ArchiveBoxXMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import Button from "./Button";
import RoutineItemForm from "./RoutineItemForm";
import { RoutineItemType } from "./types";

const RoutineItemSettings = ({
  item,
  close,
  save,
}: {
  item: RoutineItemType;
  close: () => void;
  save: (item: RoutineItemType, a?: boolean) => void;
}) => {
  const [localItem, setLocalItem] = useState(item);
  useEffect(() => {
    setLocalItem(item);
  }, [item]);
  return (
    <div>
      <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="flex items-start">
          {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 grow-0 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-900">
            <img
              src={getEmojiUrl(item.emoji)}
              alt={item.name}
              className={classNames("w-6 h-6 aspect-square")}
            />
          </div> */}
          <div className="mt-0 ml-4 text-left grow">
            {/* <Dialog.Title
              as="h3"
              className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100"
            >
              Update Item
            </Dialog.Title> */}

            <RoutineItemForm
              type={localItem.type}
              item={localItem}
              setItem={setLocalItem}
            />
          </div>
        </div>
      </div>
      <div className="bg-gray-50 dark:bg-gray-900 px-4 py-3 flex flex-col sm:flex-row justify-end sm:px-6 space-y-2 sm:space-y-0 sm:space-x-2">
        <Button
          label={localItem.archived ? "Restore" : "Archive"}
          icon={localItem.archived ? ArchiveBoxXMarkIcon : ArchiveBoxIcon}
          variant="outline"
          onClick={() => {
            save(
              { ...localItem, archived: !localItem.archived },
              !localItem.archived
            );
            close();
          }}
          fullWidth
          // className="sm:w-auto"
        />
        <Button
          label="Cancel"
          variant="outline"
          onClick={close}
          fullWidth
          // className="sm:w-auto"
        />
        <Button
          label="Save"
          variant="solid"
          onClick={() => {
            save(localItem);
            close();
          }}
          disabled={localItem.name.trim().length === 0}
          // className="sm:w-auto"
          fullWidth
        />
      </div>
    </div>
  );
};

export default RoutineItemSettings;
