import { XMarkIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { RoutineItemType } from "./types";
import { classNames, useTraceUpdate } from "./utils";

const RoutineCellRating = ({
  item,
  day,
  storedValue,
  storeValue,
}: {
  item: RoutineItemType;
  day?: Date;
  storedValue?: number;
  storeValue: (k: number | undefined) => void;
}) => {
  const [editing, setEditing] = useState(false);
  // const getValue = useCallback(
  //   () => (storedValue !== undefined ? storedValue : editing ? 5 : undefined),
  //   [storedValue, editing]
  // );
  const [value, setValue] = useState<number | undefined>(
    storedValue !== undefined ? storedValue : undefined
  );

  useEffect(() => {
    setValue(storedValue !== undefined ? storedValue : editing ? 5 : undefined);
  }, [storedValue, editing]);

  // console.log("cell rating storedValue", storedValue, day);
  // useEffect(() => {
  //   setValue(storedValue !== undefined ? storedValue : editing ? 5 : undefined);
  // }, [storedValue, editing]);
  // if (storedValue) console.log("CELL RAT", storedValue, value);
  const score: number | undefined =
    value === undefined
      ? undefined
      : ((item.invertScore ? 10 - value : value) - 5) / 5;

  // useEffect(() => {
  //   if (!editing) {
  //     // console.log("RoutineCellRating uE storeValue", value, editing);
  //     storeValue(value);
  //     // setValue(undefined);
  //   }
  // }, [storeValue, value, editing]);

  const endEdit = () => {
    setEditing(false);
    storeValue(value);
  };

  useTraceUpdate(
    {
      item,
      day,
      storedValue,
      storeValue,
      editing,
      value,
    },
    "RoutineCellRating"
  );
  // console.log("editing", editing);

  return (
    <div
      className={classNames(
        "px-2 py-1 h-[44px] min-w-[44px] leading-[35px] relative",
        score === undefined ? "text-gray-300 dark:text-gray-700" : "",
        score !== undefined && score >= 0.3 ? "text-green-600" : "",
        score !== undefined && score <= -0.3
          ? "text-red-600 dark:text-red-500"
          : "",
        score !== undefined && score > -0.3 && score < 0.3
          ? "text-amber-500 dark:text-amber-300"
          : ""
      )}
      onClick={() => {
        if (editing === false) setEditing(true);
      }}
    >
      {editing && (
        <div
          className="w-[150px] absolute top-0 -right-[140px] z-20 h-[44px] min-w-[44px] flex flex-row justify-center items-center"
          onBlur={() => {
            // console.log("onBlur");
            // storeValue(value);
            // setEditing(false);
            endEdit();
          }}
        >
          <input
            type="range"
            min={0}
            max={10}
            value={value !== undefined ? value : 5}
            className={classNames(
              "range appearance-none bg-transparent [&::-webkit-slider-runnable-track]:rounded-full [&::-webkit-slider-thumb]:bg-gray-300 [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-5  [&::-webkit-slider-thumb]:h-5 [&::-webkit-slider-thumb]:rounded-full focus:outline-none",
              // "[&::-webkit-slider-runnable-track]:bg-green-500",
              // score === undefined
              //   ? "[&::-webkit-slider-runnable-track]:bg-gray-600"
              //   : "",
              score !== undefined && score >= 0.3
                ? "[&::-webkit-slider-runnable-track]:bg-green-600"
                : "",
              score !== undefined && score <= -0.3
                ? "[&::-webkit-slider-runnable-track]:bg-red-600"
                : "",
              (score !== undefined && score > -0.3 && score < 0.3) ||
                score === undefined
                ? "[&::-webkit-slider-runnable-track]:bg-amber-500"
                : ""
            )}
            step={1}
            onChange={(e) =>
              setValue(Math.min(Math.max(0, parseInt(e.target.value)), 10))
            }
            onMouseUp={(e) => {
              // storeValue(value);
              // setEditing(false);
              endEdit();
            }}
            autoFocus
            // onBlur={() => {
            //   setValue(editing[1]);
            //   setEditing([false, 0]);
            // }}
          />
          <button
            onMouseDown={(e) => {
              // console.log("x und");
              // e.preventDefault();
              // e.stopPropagation();
              setValue(undefined);
              // storeValue(undefined);
              // setEditing(false);
              endEdit();
            }}
            className="bg-red-500 hover:bg-red-600 rounded-full text-white p-1 ml-1"
          >
            <XMarkIcon className="w-3 h-3" />
          </button>
        </div>
      )}
      <span className={classNames("font-semibold text-md")}>
        {value === undefined ? "-" : value}
      </span>
      <span className="text-sm">/</span>
      <span className="text-[0.5rem]">10</span>
    </div>
  );
};

export default RoutineCellRating;
