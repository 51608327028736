import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, sendPasswordResetEmail } from "./firebase";
// import "./Reset.css";
const Reset = () => {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  if (error) console.error("Reset useAuthState error", error);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate("/");
  }, [user, loading, navigate]);
  return (
    <div className="max-w-[30rem] p-4 mx-auto min-h-[calc(100%_-_100px)] flex flex-row justify-center items-center">
      <div className="flex flex-col space-y-2 w-full">
        {/* <input
          type="text"
          className="rounded-sm"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        /> */}

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium leading-6 "
          >
            Email address
          </label>
          <div className="mt-2">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:text-sm sm:leading-6"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {/* <button
          className="w-full bg-green-700 text-white rounded-sm py-2 my-1"
          onClick={() => sendPasswordResetEmail(auth, email)}
        >
          Send password reset email
        </button> */}

        <button
          className="flex w-full justify-center rounded-md bg-teal-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          onClick={() => sendPasswordResetEmail(auth, email)}
        >
          Send password reset email
        </button>
        {/* <div className="pt-8">
          Don't have an account?{" "}
          <Link
            to="/register"
            className="underline duration-200 text-teal-500 hover:text-teal-700"
          >
            Register
          </Link>{" "}
          now.
        </div> */}
      </div>
    </div>
  );
};
export default Reset;
