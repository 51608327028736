import { classNames } from "./utils";

const Button = ({
  label = "",
  variant = "solid",
  onClick,
  disabled = false,
  icon,
  size = "md",
  className = "",
  iconClassName = "",
  fullWidth = false,
}: {
  label?: string;
  variant?: "solid" | "outline" | "ghost";
  onClick: () => void;
  disabled?: boolean;
  icon?: any;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  className?: string;
  iconClassName?: string;
  fullWidth?: boolean;
}) => {
  const Icon = icon;
  return (
    <button
      type="button"
      className={classNames(
        "duration-200 inline-flex justify-center items-center font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600 sm:col-start-2",
        label ? "rounded-md" : "rounded-full grow-0 aspect-square",

        fullWidth ? "w-full" : "w-auto",

        variant === "solid"
          ? "bg-teal-600 disabled:bg-gray-600 disabled:text-gray-400 text-white shadow-sm hover:bg-teal-500"
          : "",
        variant === "outline"
          ? "border dark:border-white/40 dark:hover:border-white dark:text-white hover:border-black hover:bg-teal-500/10 dark:hover:bg-teal-500/20"
          : "",
        variant === "ghost"
          ? " hover:text-teal-500 dark:hover:text-teal-500"
          : "",

        size === "xs" && label ? "px-1 py-0 text-xs" : "",
        size === "sm" && label ? "px-1 py-1 text-xs" : "",
        size === "md" && label ? "px-3 py-1.5 text-sm" : "",
        size === "lg" && label ? "px-3 py-2 text-sm" : "",
        size === "xl" && label ? "px-3 py-2 text-sm" : "",

        size === "xs" && !label ? "p-0" : "",
        size === "sm" && !label ? "p-1" : "",
        size === "md" && !label ? "p-2" : "",
        size === "lg" && !label ? "p-3" : "",
        size === "xl" && !label ? "p-4" : "",

        className
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && (
        <Icon
          className={classNames(
            size === "xs" ? "w-3 h-3" : "",
            size === "sm" ? "w-4 h-4" : "",
            size === "md" ? "w-5 h-5" : "",
            size === "lg" ? "w-6 h-6" : "",
            size === "xl" ? "w-7 h-7" : "",
            label ? "mr-1" : "",
            iconClassName
          )}
        />
      )}
      {label && <span className="mr-1">{label}</span>}
    </button>
  );
};
export default Button;
