import { useCallback, useMemo, useState } from "react";
import { CellValuesType, RoutineItemType } from "./types";
import { useTraceUpdate } from "./utils";
import { updateValue } from "./firebase";
import RoutineCellNumber from "./RoutineCellNumber";
import RoutineCellTask from "./RoutineCellTask";
import RoutineCellRating from "./RoutineCellRating";
import RoutineCellThumb from "./RoutineCellThumb";

const RoutineCell = ({
  item,
  day,
  // setRefreshScore,
  // userRef,
  uid,
  cellValue,
  cellValues,
}: {
  item: RoutineItemType;
  day: Date;
  // setRefreshScore: (a: number | ((b: number) => number)) => void;
  // userRef: DocumentReference;
  uid: string;
  cellValue: number;
  cellValues: CellValuesType;
  // cellValues: { [week: string]: { [day: string]: { [id: string]: number } } };
}) => {
  const [localVal, setLocal] = useState<number | undefined | false>(false);

  useTraceUpdate(
    {
      item,
      day,
      // setRefreshScore,
      uid,
      cellValue,
      localVal,
    },
    "RoutineCell"
  );
  // console.log("localVal", item.id, day, localVal);
  // const lsKey = getStorageKey(item, day);
  // const val = localStorage.getItem(lsKey);
  // const val: number | undefined = localStorage.getItem(lsKey) ? parseFloat(localStorage.getItem(lsKey)) : cellValues[lsKey];
  // const storedValue: number | undefined = val
  //   ? parseFloat(val)
  //   : cellValues[lsKey] || undefined;

  const storedValue: number | undefined = useMemo(
    () => (localVal !== false ? localVal : cellValue),
    [localVal, cellValue]
  );
  // if (item.id === 11 && getDayString(day) === "2023-03-10")
  //   console.log(
  //     "Cell cellValues",
  //     cellValue,
  //     localVal,
  //     storedValue,
  //     getWeekString(day),
  //     getDayString(day),
  //     item.id,
  //     item
  //   );

  // const val = localStorage.getItem(lsKey);
  // const storedValue: number | undefined = val ? parseFloat(val) : undefined;

  const storeValue = useCallback(
    async (v: number | undefined) => {
      // localStorage.setItem(lsKey, v?.toString() || "");
      // setLocal(v === undefined ? false : v);
      setLocal(v);
      if (uid && v !== storedValue) {
        // if (v === undefined) {
        //   // DEL
        //   console.log("remove", `vals.${lsKey}`, v);
        //   await updateDoc(userRef, {
        //     [`vals.${lsKey}`]: deleteField(),
        //   });
        // } else {
        // console.log("updateDoc", `vals.${lsKey}`, v);

        // await updateDoc(userRef, {
        //   [`vals.${lsKey}`]: v === undefined ? deleteField() : v,
        // });

        // await setDoc(
        //   doc(db, "vals", uid),
        //   {
        //     [getWeekString(day)]: {
        //       [getDayString(day)]: {
        //         [item.id]: v === undefined ? deleteField() : v,
        //       },
        //     },
        //   },
        //   { merge: true }
        // );
        // updateStorageCell(item, day, v);
        updateValue(uid, day, item, v);

        // localStorage.setItem(lsKey, v?.toString() || "");

        // }
        // setRefreshScore((a) => a + 1);
      }
    },
    [uid, storedValue, day, item]
  );
  if (item.type === "number")
    return (
      <RoutineCellNumber
        uid={uid}
        cellValues={cellValues}
        item={item}
        day={day}
        storedValue={storedValue}
        storeValue={storeValue}
      />
    );
  if (item.type === "task")
    return (
      <RoutineCellTask
        item={item}
        day={day}
        storedValue={storedValue}
        storeValue={storeValue}
      />
    );
  if (item.type === "rating")
    return (
      <RoutineCellRating
        item={item}
        day={day}
        storedValue={storedValue}
        storeValue={storeValue}
      />
    );
  if (item.type === "thumb")
    return (
      <RoutineCellThumb
        item={item}
        day={day}
        storedValue={storedValue}
        storeValue={storeValue}
      />
    );
  return <div className="px-2 py-1"></div>;
};

export default RoutineCell;
