import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
// import { useEffect, useState } from "react";
import { RoutineItemType } from "./types";
import { useTraceUpdate } from "./utils";
// import { getEmojiUrl } from "./utils";

const RoutineCellTask = ({
  item,
  day,
  storedValue,
  storeValue,
}: {
  item: RoutineItemType;
  day?: Date;
  storedValue?: number;
  storeValue: (k: number | undefined) => void;
}) => {
  // const [value, setValue] = useState<number | undefined>(storedValue);

  useTraceUpdate({ item, day, storedValue, storeValue }, "RoutineCellTask");

  // if (item.id === 18 && day && getDayString(day) === "2023-03-10")
  //   console.log("CELL TASK val", storedValue, day);
  // useEffect(() => {
  //   storeValue(value);
  // }, [value, storeValue]);
  return (
    <div
      className="h-[44px] min-w-[44px] flex justify-center items-center"
      onClick={
        () => {
          if (storedValue === undefined) storeValue(1);
          else if (storedValue === 1) storeValue(-1);
          else storeValue(undefined);
        }
        // setValue((v) => {
        //   if (v === undefined) return 1;
        //   if (v === 1) return -1;
        //   return undefined;
        // })
      }
    >
      {storedValue === undefined && (
        <input
          type="checkbox"
          checked={false}
          readOnly
          className="h-4 w-4 rounded border-gray-300 dark:border-[#212c3b] bg-transparent pointer-events-none"
        />
      )}
      {storedValue === 1 && <CheckIcon className="w-5 h-5 text-green-600" />}
      {storedValue === -1 && (
        <XMarkIcon className="w-5 h-5 text-red-600 dark:text-red-500" />
      )}
    </div>
  );
};

export default RoutineCellTask;
